import React, { useEffect, useState } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import styles from "../styles/Login.module.css";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "./helpers/Helpers";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

interface LoginProps {
  onFormSwitch?: (message: any) => void;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const Login: React.FC<LoginProps> = ({ onFormSwitch }: LoginProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [login, setLogin] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

  const handleLogin = async () => {
    if (login.email === "" || login.password === "") {
      enqueueSnackbar("Email and Password are required.", { variant: "error" });
      return;
    }

    if (!passwordRegex.test(login.password)) {
      enqueueSnackbar(
        "Password must be 8-20 characters long, include at least one lowercase letter, one uppercase letter, one number, and one special character.",
        { variant: "error" }
      );
      return;
    }

    if (!validateEmail(login.email)) {
      enqueueSnackbar("Invalid email address.", { variant: "error" });
      return;
    }

    // if (!captchaValue) {

    //   enqueueSnackbar("Please complete the CAPTCHA verification.", {
    //     variant: "warning",
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/auth/signin`, {
        username: login.email,
        password: login.password,
      });

      if (response.data !== "Not registered") {
        // if (response.data.message !== "Payment incomplete") {
        if (response.data !== "Not verified") {
          if (response !== null) {
            setLoading(false);

            sessionStorage.setItem("accessToken", response.data?.accessToken);
            sessionStorage.setItem("userId", response.data?.userId);
            sessionStorage.setItem("companyId", response.data?.userId);
            sessionStorage.setItem("userName", response.data?.name);
            sessionStorage.setItem("userEmail", response.data?.email);

            navigate(
              "/home?userId=" +
                response.data?.userId +
                "&" +
                "companyId=" +
                response.data?.userId
            );

            // navigate("/otp_verification");

            enqueueSnackbar("Login Successful", { variant: "success" });
          }
        } else {
          navigate("/resendforverify?email=" + login.email);
          enqueueSnackbar(
            "Please complete verification process. Resended Verification Mail",
            {
              variant: "info",
            }
          );
        }
        // } else {
        //   navigate(
        //     "/myPay?email=" + login.email + "&name=" + response.data.username
        //   );
        //   enqueueSnackbar("Please complete your payment process", {
        //     variant: "info",
        //   });
        // }
      } else {
        navigate("/registration");
        enqueueSnackbar("Please complete registration", { variant: "info" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Login failed. Please try again.", { variant: "error" });
      console.log(error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value); // Update the CAPTCHA value when it changes
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            margin: "20px auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          {/* Logo Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src="./defaultLogo.png"
              alt="EasySign Logo"
              style={{ width: "80%", height: "auto" }}
            />
          </div>

          {/* Login Title */}
          <div className={styles.loginTitle}>
            <p className={styles.upperInfo}>Sign In to your account</p>
          </div>

          {/* Login Form */}
          <div className={styles.loginForm}>
            <div className={styles.feild}>
              <div className={styles.label}>
                <p>Email</p>
              </div>
              <input
                className={styles.inputFeild}
                type="email"
                name="email"
                placeholder="Type in your email.."
                required
                onChange={onChange}
                onKeyDown={handleKeyPress}
              />
            </div>

            <div className={styles.feild}>
              <div className={styles.label}>
                <p>Password</p>
              </div>
              <input
                className={styles.inputFeild}
                type="password"
                name="password"
                placeholder="password"
                required
                onChange={onChange}
                onKeyDown={handleKeyPress}
              />
            </div>

            <Button
              variant="contained"
              onClick={handleLogin}
              disabled={loading}
              style={{
                width: "85%",
                padding: "5px",
                borderRadius: "8px",
                fontWeight: "normal",
                fontSize: "17px",
                backgroundColor: loading ? "#c0c0c0" : "#007bff",
                color: "#fff",
                border: "none",
                transition: "background-color 0.3s ease",
                marginTop: "10px",
              }}
            >
              {loading ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <CircularProgress size={20} color="inherit" />
                  <span>Loading...</span>
                </div>
              ) : (
                "Login"
              )}
            </Button>

            <div style={{ paddingTop: "5%", textAlign: "center" }}>
              <Link to="/registration" style={{ textDecoration: "none" }}>
                <p style={{ color: "black" }}>
                  Don't have an account? <strong>Register here!</strong>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
