import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Typography,
  Link,
  Grid,
  Box,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import AgreementModal from "./AgreementModal";
import axios from "axios";
import { useSnackbar } from "notistack";

const MainModal = ({ isOpen, onClose, userName, userEmail }) => {
  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";

  const [logoBase64, setLogoBase64] = useState(null);
  const [signerImage, setSignerImage] = useState(logoBase64);
  const [brandName, setBrandName] = useState("");
  const [
    SignerAgreementBrandNameFontFamily,
    setSignerAgreementBrandNameFontFamily,
  ] = useState("Arial");
  const [
    SignerAgreementBrandHeadingTextColor,
    setSignerAgreementBrandHeadingTextColor,
  ] = useState("#348eda");
  const [
    SignerAgreementBrandNameFontSize,
    setSignerAgreementBrandNameFontSize,
  ] = useState("36px");
  const [
    SignerAgreementBrandNameFontStyle,
    setSignerAgreementBrandNameFontStyle,
  ] = useState("");
  const [
    SignerAgreementBrandNameFontWeight,
    setSignerAgreementBrandNameFontWeight,
  ] = useState("700");
  const [
    SignerAgreementEmailBodyBackgroundColor,
    setSignerAgreementEmailBodyBackgroundColor,
  ] = useState("#ffffff");
  const [
    SignerAgreementEmailBodyTextColor,
    setSignerAgreementEmailBodyTextColor,
  ] = useState("black");

  const [SignerAgreementFontFamily, setSignerAgreementFontFamily] =
    useState("Arial");
  const [SignerAgreementBtnFontFamily, setSignerAgreementBtnFontFamily] =
    useState("Arial");
  const [SignerAgreementBodyFontSize, setSignerAgreementBodyFontSize] =
    useState("16px");
  const [SignerAgreementBodyFontStyle, setSignerAgreementBodyFontStyle] =
    useState("");
  const [SignerAgreementBodyFontWeight, setSignerAgreementBodyFontWeight] =
    useState("");
  const [SignerAgreementbuttonText, setSignerAgreementButtonText] =
    useState("Agree");
  const [SignerAgreementbuttonColor, setSignerAgreementButtonColor] =
    useState("#348eda");
  const [SignerAgreementbuttonTextColor, setSignerAgreementButtonTextColor] =
    useState("white");
  const [SignerAgreementbuttonFontSize, setSignerAgreementButtonFontSize] =
    useState("12.5px");
  const [SignerAgreementButtonFontStyle, setSignerAgreementButtonFontStyle] =
    useState("");
  const [SignerAgreementButtonFontWeight, setSignerAgreementButtonFontWeight] =
    useState("");

  const [
    SignerAgreementBrandHeadingBackgroundColor,
    setSignerAgreementBrandHeadingBackgroundColor,
  ] = useState("#b9cbfb");

  const [
    SignerAgreementEmailBodyLinkFontFamily,
    setSignerAgreementEmailBodyLinkFontFamily,
  ] = useState("Arial");

  const [
    SignerAgreementEmailBodyLinkFontSize,
    setSignerAgreementEmailBodyLinkFontSize,
  ] = useState("16px");

  const [
    SignerAgreementEmailBodyLinkFontStyle,
    setSignerAgreementEmailBodyLinkFontStyle,
  ] = useState("");

  const [
    SignerAgreementEmailBodyLinkFontWeight,
    setSignerAgreementEmailBodyLinkFontWeight,
  ] = useState("");

  const [
    SignerAgreementEmailBodyLinkTextColor,
    setSignerAgreementEmailBodyLinkTextColor,
  ] = useState("#007BFF");

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result); // If it's a string, resolve with the Base64 string
        } else {
          reject(
            new Error("Failed to convert to Base64: result is not a string")
          );
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    // Fetch the logo as a blob and convert to Base64
    fetch("./defaultLogo.png")
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "defaultLogo.png", { type: blob.type });
        return convertToBase64(file);
      })
      .then((base64) => {
        setLogoBase64(base64);
      })
      .catch((error) =>
        console.error("Error converting logo to Base64:", error)
      );
  }, []);

  useEffect(() => {
    if (logoBase64) {
      setSignerImage(logoBase64);
    }
  }, [logoBase64]);

  const handleSetStates = (resData) => {
    const { signerAgreement } = resData;

    // Signer Agreement Data

    setBrandName(signerAgreement.heading.name);

    setSignerAgreementBrandNameFontSize(signerAgreement.heading.fontSize);
    setSignerAgreementBrandNameFontFamily(signerAgreement.heading.fontFamily);
    setSignerAgreementBrandHeadingTextColor(signerAgreement.heading.fontColor);
    setSignerAgreementBrandNameFontStyle(signerAgreement.heading.fontStyle);
    setSignerAgreementBrandNameFontWeight(signerAgreement.heading.fontWeight);
    setSignerImage(signerAgreement.heading.img || logoBase64);
    setSignerAgreementBrandHeadingBackgroundColor(
      signerAgreement.heading.backgroundColor
    );

    // Body
    setSignerAgreementEmailBodyBackgroundColor(
      signerAgreement.body.backgroundColor
    );
    setSignerAgreementEmailBodyTextColor(signerAgreement.body.fontColor);
    setSignerAgreementFontFamily(signerAgreement.body.fontFamily);
    setSignerAgreementBodyFontSize(signerAgreement.body.fontSize);
    setSignerAgreementBodyFontStyle(signerAgreement.body.fontStyle);
    setSignerAgreementBodyFontWeight(signerAgreement.body.fontWeight);

    // Button
    setSignerAgreementButtonText(signerAgreement.button.name);
    setSignerAgreementButtonColor(signerAgreement.button.backgroundColor);
    setSignerAgreementButtonTextColor(signerAgreement.button.fontColor);
    setSignerAgreementButtonFontSize(signerAgreement.button.fontSize);
    setSignerAgreementBtnFontFamily(signerAgreement.button.fontFamily);
    setSignerAgreementButtonFontStyle(signerAgreement.button.fontStyle);
    setSignerAgreementButtonFontWeight(signerAgreement.button.fontWeight);

    // Link
    setSignerAgreementEmailBodyLinkTextColor(
      signerAgreement.link?.fontColor || "#007BFF"
    );
    setSignerAgreementEmailBodyLinkFontFamily(
      signerAgreement.link?.fontFamily || "Arial"
    );
    setSignerAgreementEmailBodyLinkFontSize(
      signerAgreement.link?.fontSize || "16px"
    );
    setSignerAgreementEmailBodyLinkFontStyle(
      signerAgreement.link?.fontStyle || ""
    );
    setSignerAgreementEmailBodyLinkFontWeight(
      signerAgreement.link?.fontWeight || ""
    );
  };

  const handleGetBrandingDetailsApi = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/branding/get-branding?userId=&emailId=${userEmail}`,
        {
          headers: {
            key: headerKey,
            // Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      setLoading(true);

      handleSetStates(response?.data);
      setLoading(false);
    } catch (error) {
      // enqueueSnackbar("Failed to get branding details", { variant: "error" });
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBrandingDetailsApi();
  }, []);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleAgreeClick = () => {
    if (isChecked) {
      onClose(); // Close the modal only when "Agree" is clicked
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
          zIndex="100000"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Full-screen blurred overlay */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(0.5px)", // Blur effect
              zIndex: 1200,
            }}
          />

          {/* Modal Content */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",

              // backgroundColor: "lightpink",
              backgroundColor: "#f0f8ff",

              zIndex: 1300,
              // padding: "20px 40px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <>
              {/* Full-Screen Overlay */}
              <div
                style={{
                  backgroundColor: SignerAgreementEmailBodyBackgroundColor,
                  color: SignerAgreementEmailBodyTextColor,
                  fontFamily: SignerAgreementFontFamily,
                  // marginTop: "10px",
                }}
              />

              {/* Modal Content */}
              <div
                style={{
                  width: "100%",
                  backgroundColor: SignerAgreementEmailBodyBackgroundColor,
                  color: SignerAgreementEmailBodyTextColor,
                  fontFamily: SignerAgreementFontFamily,
                  // padding: "20px 50px",
                  // padding: "0px 50px 10px 50px",

                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* Header Section */}

                <div
                  style={{
                    backgroundColor: SignerAgreementBrandHeadingBackgroundColor,
                    width: "100%",
                    padding: "5px",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    style={{ marginLeft: "0px" }}
                  >
                    {/* Image Section */}
                    <Box
                      position="relative"
                      display="inline-block"
                      marginRight="16px"
                    >
                      <img
                        alt="Signer Logo"
                        src={signerImage}
                        style={{
                          // width: "auto",
                          // height: "auto",
                          width: "auto",
                          height: "70px",
                          objectFit: "cover",
                          marginLeft: "10px",
                        }}
                      />
                    </Box>

                    {/* Name Section */}
                    <Box>
                      <span
                        style={{
                          fontSize: SignerAgreementBrandNameFontSize,
                          fontFamily: SignerAgreementBrandNameFontFamily,
                          color: SignerAgreementBrandHeadingTextColor,
                          fontStyle: SignerAgreementBrandNameFontStyle,
                          fontWeight: SignerAgreementBrandNameFontWeight,
                          marginLeft: "10px", // Adds spacing between the image and text
                        }}
                      >
                        {brandName}
                      </span>
                    </Box>
                  </Box>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "2px 50px 10px 50px",
                  }}
                >
                  {/* Left Section */}
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      paddingRight: "24px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        // fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: SignerAgreementBodyFontSize,
                        fontFamily: SignerAgreementFontFamily,
                        color: SignerAgreementEmailBodyTextColor,
                        fontStyle: SignerAgreementBodyFontStyle,
                        fontWeight: SignerAgreementBodyFontWeight,
                        // fontSize: "0.85rem",
                        marginTop: "10px",
                      }}
                    >
                      Please read the
                      <FileCopy
                        style={{
                          marginRight: "5px",
                          marginLeft: "5px",
                          // color: "#007BFF",
                          color: SignerAgreementEmailBodyLinkTextColor,

                          fontSize: SignerAgreementBodyFontSize,
                          fontFamily: SignerAgreementFontFamily,
                          fontStyle: SignerAgreementBodyFontStyle,
                          // fontWeight: SignerAgreementBodyFontWeight,

                          // fontSize: "1rem",
                        }}
                      />
                      <Link
                        component="button"
                        onClick={() => setAgreementModalOpen(true)}
                        style={{
                          // color: "#007BFF",
                          textDecoration: "none",
                          // fontSize: "0.8rem",

                          // color: "#007BFF",
                          color: SignerAgreementEmailBodyLinkTextColor,

                          fontSize: SignerAgreementEmailBodyLinkFontSize,
                          fontFamily: SignerAgreementEmailBodyLinkFontFamily,
                          fontStyle: SignerAgreementEmailBodyLinkFontStyle,
                          fontWeight: SignerAgreementEmailBodyLinkFontWeight,
                        }}
                        sx={{
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        Electronic Record and Signature Disclosure.
                      </Link>
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.75rem",
                      }}
                    >
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                        style={{
                          transform: "scale(0.8)",
                          marginRight: "1px",
                          // backgroundColor: SignerAgreementbuttonColor,
                          color: SignerAgreementbuttonColor,
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          // fontSize: "0.75rem",
                          fontSize: SignerAgreementBodyFontSize,
                          fontFamily: SignerAgreementFontFamily,
                          color: SignerAgreementEmailBodyTextColor,
                          fontStyle: SignerAgreementBodyFontStyle,
                          fontWeight: SignerAgreementBodyFontWeight,
                        }}
                      >
                        I agree to use electronic records and signatures.
                      </Typography>
                    </div>
                  </Grid>

                  {/* Right Section */}
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAgreeClick}
                      // disabled={!isChecked}
                      style={{
                        backgroundColor: isChecked
                          ? SignerAgreementbuttonColor
                          : "#d3d3d3",
                        color: isChecked
                          ? SignerAgreementbuttonTextColor
                          : "#a9a9a9",
                        fontSize: SignerAgreementbuttonFontSize,
                        padding: "6px 12px",
                        cursor: isChecked ? "pointer" : "not-allowed",
                        fontWeight: SignerAgreementButtonFontWeight,
                        fontStyle: SignerAgreementButtonFontStyle,
                        fontFamily: SignerAgreementBtnFontFamily,
                      }}
                    >
                      {SignerAgreementbuttonText}
                    </Button>
                  </Grid>
                </div>
              </div>
            </>
          </div>

          {isAgreementModalOpen && (
            <AgreementModal
              isOpen={isAgreementModalOpen}
              onClose={() => setAgreementModalOpen(false)}
              userName={userName}
              userEmail={userEmail}
            />
          )}
        </>
      )}
    </>
  );
};

export default MainModal;
