import { Avatar, Box } from "@mui/material";
import React from "react";

const EmailTemplate = ({
  title,
  customMessage,
  redirectUrl,
  senderName,
  senderEmail,
  recieverName,
  EmailBodyBackgroundColor,
  EmailBodyTextColor,
  fontStyle,
  mainMessage,
  buttonColor,
  buttonTextColor,
  buttonFontSize,
  buttonText,
  emailImage,
  brandName,
  BrandNameFontSize,
  BrandNameFontFamily,
  BrandHeadingTextColor,
  BrandNameFontStyle,
  BrandNameFontWeight,

  EmailBodyFontSize,
  EmailBodyFontStyle,
  EmailBodyFontWeight,
  EmailBtnFontFamily,

  EmailButtonFontStyle,
  EmailButtonFontWeight,
}) => {
  return (
    <div
      style={{
        fontSize: "16px",
        backgroundColor: "#fdfdfd",
        margin: 0,
        padding: 0,
        // color : EmailBodyTextColor,
        fontFamily: fontStyle,
        lineHeight: 1.5,
      }}
    >
      <table
        style={{
          width: "100%",
          backgroundColor: "#fdfdfd",
          borderCollapse: "separate",
        }}
        cellPadding={0}
        cellSpacing={0}
      >
        <tbody>
          <tr>
            <td></td>
            <td
              style={{
                width: "600px",
                maxWidth: "600px",
                margin: "0 auto",
                padding: "10px",
                // color : EmailBodyTextColor,
                fontFamily: fontStyle,
              }}
            >
              <div
                style={{
                  maxWidth: "600px",
                  margin: "0 auto",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "30px",

                    backgroundColor: EmailBodyBackgroundColor,
                    color: EmailBodyTextColor,
                    fontFamily: fontStyle,

                    border: "1px solid #f0f0f0",
                  }}
                >
                  <table
                    style={{ color: EmailBodyTextColor, fontFamily: fontStyle }}
                    width="100%"
                  >
                    <tbody>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{ margin: "10px 10px 0px 10px" }}
                      >
                        {/* Image Section */}
                        <Box
                          position="relative"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          marginBottom="-25px"
                          height="140px"
                        >
                          <img
                            alt="Email Logo"
                            src={emailImage}
                            style={{
                              width: "auto",
                              height: "130px",
                              objectFit: "cover",
                              padding: "20px",
                            }}
                          />
                        </Box>

                        {/* Title Section */}
                        <Box
                          style={{
                            padding: "20px",
                            width: "100%",
                            textAlign: "center",
                            cursor: "default",
                          }}
                        >
                          <span
                            style={{
                              fontSize: BrandNameFontSize,
                              fontFamily: BrandNameFontFamily,
                              color: BrandHeadingTextColor,
                              fontStyle: BrandNameFontStyle,
                              fontWeight: BrandNameFontWeight,
                            }}
                          >
                            {brandName}
                          </span>
                        </Box>
                      </Box>

                      <tr>
                        <td
                          style={{
                            padding: "0px 30px 30px 30px",
                            color: EmailBodyTextColor,
                            fontFamily: fontStyle,
                          }}
                        >
                          <h2
                            style={{
                              margin: 0,
                              marginBottom: "25px",

                              // fontWeight: 300,
                              fontWeight: EmailBodyFontWeight,

                              fontSize: "25px",
                              fontStyle: EmailBodyFontStyle,

                              color: EmailBodyTextColor,
                            }}
                          >
                            Hello {recieverName},
                          </h2>
                          <p
                            style={{
                              color: EmailBodyTextColor,
                              background: EmailBodyBackgroundColor,
                              fontFamily: fontStyle,
                              fontStyle: EmailBodyFontStyle,
                              fontSize: EmailBodyFontSize,
                              fontWeight: EmailBodyFontWeight,
                            }}
                          >
                            {/* <strong>{mainMessage}</strong> */}
                            {mainMessage}
                          </p>
                          <p
                            style={{
                              color: EmailBodyTextColor,
                              background: EmailBodyBackgroundColor,
                              fontFamily: fontStyle,
                              fontStyle: EmailBodyFontStyle,
                              fontSize: EmailBodyFontSize,
                              fontWeight: EmailBodyFontWeight,
                            }}
                          >
                            {customMessage}
                          </p>
                          <table
                            style={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td align="center">
                                  <a
                                    // href={redirectUrl}
                                    href="#"
                                    style={{
                                      textDecoration: "none",
                                      display: "inline-block",
                                      margin: 0,

                                      //   color: "#ffffff",
                                      //   backgroundColor: "#348eda",
                                      //   fontSize: "14px",

                                      backgroundColor: buttonColor,
                                      color: buttonTextColor,

                                      fontSize: buttonFontSize,

                                      fontFamily: EmailBtnFontFamily,

                                      fontStyle: EmailButtonFontStyle,

                                      fontWeight: EmailButtonFontWeight,

                                      // border: "solid 1px #348eda",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                      padding: "12px 45px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    {buttonText}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p
                            style={{
                              color: EmailBodyTextColor,
                              background: EmailBodyBackgroundColor,
                              fontFamily: fontStyle,
                              fontStyle: EmailBodyFontStyle,
                              fontSize: EmailBodyFontSize,
                              fontWeight: EmailBodyFontWeight,
                            }}
                          >
                            Thank you,
                            <br />
                            {senderName}
                            <br />
                            {senderEmail}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    clear: "both",
                    width: "100%",
                    // color: EmailBodyTextColor,
                    // fontFamily: fontStyle,

                    color: EmailBodyTextColor,
                    background: EmailBodyBackgroundColor,
                    fontFamily: EmailBodyFontStyle,
                    fontSize: EmailBodyFontSize,
                    fontWeight: EmailBodyFontWeight,
                  }}
                >
                  <table
                    width="100%"
                    style={{
                      // fontFamily: fontStyle,
                      // fontSize: "12px",

                      color: EmailBodyTextColor,
                      background: EmailBodyBackgroundColor,
                      fontFamily: EmailBodyFontStyle,
                      fontSize: EmailBodyFontSize,
                      fontWeight: EmailBodyFontWeight,
                    }}
                  >
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmailTemplate;
