import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Typography,
  Link,
  Grid,
  Avatar,
  Box,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";

const SignerAgreementTemplate = ({
  SignerAgreementEmailBodyBackgroundColor,
  SignerAgreementEmailBodyTextColor,
  SignerAgreementfontStyle,
  SignerAgreementbuttonColor,
  SignerAgreementbuttonTextColor,
  SignerAgreementbuttonFontSize,
  SignerAgreementbuttonText,
  signerImage,
  brandName,
  SignerAgreementBrandNameFontSize,
  SignerAgreementBrandNameFontFamily,
  SignerAgreementBrandHeadingTextColor,
  SignerAgreementBrandNameFontStyle,
  SignerAgreementBrandNameFontWeight,

  SignerAgreementBodyFontSize,
  SignerAgreementBodyFontStyle,
  SignerAgreementBodyFontWeight,
  SignerAgreementButtonFontStyle,
  SignerAgreementButtonFontWeight,
  SignerAgreementBtnFontFamily,

  SignerAgreementEmailBodyLinkTextColor,

  SignerAgreementEmailBodyLinkFontSize,
  SignerAgreementEmailBodyLinkFontFamily,
  SignerAgreementEmailBodyLinkFontStyle,
  SignerAgreementEmailBodyLinkFontWeight,

  SignerAgreementBrandHeadingBackgroundColor,
}) => {
  const [isChecked, setChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleAgreeClick = () => {
    if (isChecked) {
      console.log("Agreement Accepted");
    }
  };

  return (
    <>
      {/* Full-Screen Overlay */}
      <div
        style={{
          backgroundColor: SignerAgreementEmailBodyBackgroundColor,
          color: SignerAgreementEmailBodyTextColor,
          fontFamily: SignerAgreementfontStyle,
          // marginTop: "10px",
        }}
      />

      {/* Modal Content */}
      <div
        style={{
          width: "100%",
          backgroundColor: SignerAgreementEmailBodyBackgroundColor,
          color: SignerAgreementEmailBodyTextColor,
          fontFamily: SignerAgreementfontStyle,
          // padding: "20px 50px",
          // padding: "0px 50px 10px 50px",
          // padding: "10px 0",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Header Section */}

        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // style={{ margin: "10px" }}
        >
          <Box sx={{ width: "20%" }}>
            <Box position="relative" display="inline-block">
              <Avatar
                alt="Signer Logo"
                src={signerImage}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          </Box>

          <Box sx={{ width: "80%", marginRight: "20%" }}>
            <div
              style={{
                padding: "8px",
                width: "100%",
                height: "12vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "default",
              }}
            >
              <span
                style={{
                  fontSize: SignerAgreementBrandNameFontSize,
                  fontFamily: SignerAgreementBrandNameFontFamily,
                  color: SignerAgreementBrandHeadingTextColor,
                  fontStyle: SignerAgreementBrandNameFontStyle,
                  fontWeight: SignerAgreementBrandNameFontWeight,
                }}
              >
                {brandName}
              </span>
            </div>
          </Box>
        </Box> */}

        <div
          style={{
            backgroundColor: SignerAgreementBrandHeadingBackgroundColor,
            width: "100%",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            style={{ marginLeft: "0px" }}
          >
            {/* Image Section */}
            <Box position="relative" display="inline-block" marginRight="16px">
              <img
                alt="Signer Logo"
                src={signerImage}
                style={{
                  // width: "auto",
                  // height: "auto",
                  width: "auto",
                  height: "70px",
                  objectFit: "cover",
                  marginLeft: "10px",
                }}
              />
            </Box>

            {/* Name Section */}
            <Box>
              <span
                style={{
                  fontSize: SignerAgreementBrandNameFontSize,
                  fontFamily: SignerAgreementBrandNameFontFamily,
                  color: SignerAgreementBrandHeadingTextColor,
                  fontStyle: SignerAgreementBrandNameFontStyle,
                  fontWeight: SignerAgreementBrandNameFontWeight,
                  marginLeft: "10px", // Adds spacing between the image and text
                }}
              >
                {brandName}
              </span>
            </Box>
          </Box>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 50px 10px 50px",
          }}
        >
          {/* Left Section */}
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingRight: "24px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                // fontWeight: "bold",
                marginBottom: "10px",
                fontSize: SignerAgreementBodyFontSize,
                fontFamily: SignerAgreementfontStyle,
                color: SignerAgreementEmailBodyTextColor,
                fontStyle: SignerAgreementBodyFontStyle,
                fontWeight: SignerAgreementBodyFontWeight,
                // fontSize: "0.85rem",
                marginTop: "10px",
              }}
            >
              Please read the
              <FileCopy
                style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                  // color: "#007BFF",
                  color: SignerAgreementEmailBodyLinkTextColor,

                  fontSize: SignerAgreementBodyFontSize,
                  fontFamily: SignerAgreementfontStyle,
                  fontStyle: SignerAgreementBodyFontStyle,
                  // fontWeight: SignerAgreementBodyFontWeight,

                  // fontSize: "1rem",
                }}
              />
              <Link
                component="button"
                // onClick={() => setAgreementModalOpen(true)}

                style={{
                  // color: "#007BFF",
                  textDecoration: "none",
                  // fontSize: "0.8rem",

                  // color: "#007BFF",
                  color: SignerAgreementEmailBodyLinkTextColor,

                  fontSize: SignerAgreementEmailBodyLinkFontSize,
                  fontFamily: SignerAgreementEmailBodyLinkFontFamily,
                  fontStyle: SignerAgreementEmailBodyLinkFontStyle,
                  fontWeight: SignerAgreementEmailBodyLinkFontWeight,
                }}
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Electronic Record and Signature Disclosure.
              </Link>
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.75rem",
              }}
            >
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
                style={{
                  transform: "scale(0.8)",
                  marginRight: "1px",
                  color: SignerAgreementbuttonColor,
                }}
              />
              <Typography
                variant="body2"
                style={{
                  // fontSize: "0.75rem",
                  fontSize: SignerAgreementBodyFontSize,
                  fontFamily: SignerAgreementfontStyle,
                  color: SignerAgreementEmailBodyTextColor,
                  fontStyle: SignerAgreementBodyFontStyle,
                  fontWeight: SignerAgreementBodyFontWeight,
                }}
              >
                I agree to use electronic records and signatures.
              </Typography>
            </div>
          </Grid>

          {/* Right Section */}
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgreeClick}
              // disabled={!isChecked}
              style={{
                backgroundColor: isChecked
                  ? SignerAgreementbuttonColor
                  : "#d3d3d3",
                color: isChecked ? SignerAgreementbuttonTextColor : "#a9a9a9",
                fontSize: SignerAgreementbuttonFontSize,
                padding: "6px 12px",
                cursor: isChecked ? "pointer" : "not-allowed",
                fontWeight: SignerAgreementButtonFontWeight,
                fontStyle: SignerAgreementButtonFontStyle,
                fontFamily: SignerAgreementBtnFontFamily,
              }}
            >
              {SignerAgreementbuttonText}
            </Button>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SignerAgreementTemplate;
