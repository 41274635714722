import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Button,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Headers } from "./Headers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import EmailTemplate from "./EmailTemplate";

import SignerAgreementTemplate from "./SignerAgreementTemplate";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import ConfirmationPopup from "./ConfirmationPopup";
import DefaultIcon from "@mui/icons-material/Restore";

export const Branding: React.FC = () => {
  const baseURL = process.env.REACT_APP_API_URL || "";
  const headerKey = process.env.REACT_APP_API_HEADER_KEY || "";
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTab, setSelectedTab] = useState(0);

  const [logoBase64, setLogoBase64] = useState<string | null>(null);

  useEffect(() => {
    fetch("./defaultLogo.png")
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "defaultLogo.png", { type: blob.type });
        return convertToBase64(file);
      })
      .then((base64) => {
        if (typeof base64 === "string") {
          setLogoBase64(base64);
        }
      })
      .catch((error) =>
        console.error("Error converting logo to Base64:", error)
      );
  }, []);

  useEffect(() => {
    if (logoBase64) {
      setEmailImage(logoBase64);
      setsignerImage(logoBase64);
    }
  }, [logoBase64]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setExpanded("panel1");
  };

  const [brandName, setBrandName] = useState<any>("");

  // const [emailImage, setEmailImage] = useState<any>(
  //   // "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png"
  //   // easySignLogo
  //   logoBase64
  // );

  const [emailImage, setEmailImage] = useState<string | null>(logoBase64);

  const [EmailButtonText, setEmailButtonText] = useState<any>(
    "Take me to the document"
  );
  const [EmailButtonBgColor, setEmailButtonBgColor] = useState<any>("#348eda");

  const [EmailBtnFontFamily, setEmailBtnFontFamily] = useState<any>("Arial");

  const [EmailButtonFontStyle, setEmailButtonFontStyle] =
    useState<any>("normal");
  const [EmailButtonFontWeight, setEmailButtonFontWeight] =
    useState<any>("normal");

  const [buttonTextColor, setButtonTextColor] = useState<any>("#ffffff");
  const [buttonFontSize, setButtonFontSize] = useState<any>("16px");

  const [EmailBodyBackgroundColor, setEmailBodyBackgroundColor] =
    useState<any>("#ffffff");
  const [EmailBodyTextColor, setEmailBodyTextColor] = useState<any>("black");

  const [EmailFontFamily, setEmailFontFamily] = useState<any>("Arial");

  const [EmailBodyFontSize, setEmailBodyFontSize] = useState<any>("16px");
  const [EmailBodyFontStyle, setEmailBodyFontStyle] = useState<any>("normal");
  const [EmailBodyFontWeight, setEmailBodyFontWeight] = useState<any>("normal");

  const [BrandNameFontFamily, setBrandNameFontFamily] = useState<any>("Arial");
  const [BrandNameFontStyle, setBrandNameFontStyle] = useState<any>("normal");
  const [BrandNameFontWeight, setBrandNameFontWeight] = useState<any>("700");
  const [BrandNameFontSize, setBrandNameFontSize] = useState<any>("36px");
  const [BrandHeadingTextColor, setBrandHeadingTextColor] =
    useState<any>("#348eda");

  const resetEmailTemplate = () => {
    setBrandName("");
    // setEmailImage(easySignLogo);
    // setsignerImage(easySignLogo);

    setEmailImage(logoBase64);
    setsignerImage(logoBase64);

    setBrandHeadingTextColor("#348eda");
    setBrandNameFontFamily("Arial");
    setBrandNameFontSize("36px");
    setBrandNameFontStyle("normal");
    setBrandNameFontWeight("700");

    setEmailBodyBackgroundColor("#ffffff");
    setEmailBodyTextColor("black");
    setEmailFontFamily("Arial");
    setEmailBodyFontSize("16px");
    setEmailBodyFontStyle("normal");
    setEmailBodyFontWeight("normal");

    setEmailButtonText("Take me to the document");
    setButtonTextColor("#ffffff");
    setButtonFontSize("16px");
    setEmailButtonBgColor("#348eda");
    setEmailBtnFontFamily("Arial");
    setEmailButtonFontStyle("normal");
    setEmailButtonFontWeight("normal");
  };

  // const [signerImage, setsignerImage] = useState<any>(
  //   // "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png"
  //   // easySignLogo
  //   logoBase64
  // );

  const [signerImage, setsignerImage] = useState<string | null>(logoBase64);

  const [
    SignerAgreementBrandNameFontFamily,
    setSignerAgreementBrandNameFontFamily,
  ] = useState<any>("Arial");
  const [
    SignerAgreementBrandHeadingTextColor,
    setSignerAgreementBrandHeadingTextColor,
  ] = useState<any>("#348eda");

  const [
    SignerAgreementBrandHeadingBackgroundColor,
    setSignerAgreementBrandHeadingBackgroundColor,
  ] = useState<any>("#b9cbfb");

  const [
    SignerAgreementBrandNameFontSize,
    setSignerAgreementBrandNameFontSize,
  ] = useState<any>("36px");

  const [
    SignerAgreementBrandNameFontStyle,
    setSignerAgreementBrandNameFontStyle,
  ] = useState<any>("");
  const [
    SignerAgreementBrandNameFontWeight,
    setSignerAgreementBrandNameFontWeight,
  ] = useState<any>("700");

  const [
    SignerAgreementEmailBodyBackgroundColor,
    setSignerAgreementEmailBodyBackgroundColor,
  ] = useState<any>("#ffffff");
  const [
    SignerAgreementEmailBodyTextColor,
    setSignerAgreementEmailBodyTextColor,
  ] = useState<any>("black");

  const [SignerAgreementFontFamily, setSignerAgreementFontFamily] =
    useState<any>("Arial");

  const [SignerAgreementBtnFontFamily, setSignerAgreementBtnFontFamily] =
    useState<any>("Arial");

  const [SignerAgreementBodyFontSize, setSignerAgreementBodyFontSize] =
    useState<any>("16px");

  const [SignerAgreementBodyFontStyle, setSignerAgreementBodyFontStyle] =
    useState<any>("");
  const [SignerAgreementBodyFontWeight, setSignerAgreementBodyFontWeight] =
    useState<any>("");

  const [SignerAgreementbuttonText, setSignerAgreementButtonText] =
    useState<any>("Agree");
  const [SignerAgreementbuttonColor, setSignerAgreementButtonColor] =
    useState<any>("#348eda");
  const [SignerAgreementbuttonTextColor, setSignerAgreementButtonTextColor] =
    useState<any>("white");

  const [SignerAgreementbuttonFontSize, setSignerAgreementButtonFontSize] =
    useState<any>("12.5px");

  const [SignerAgreementButtonFontStyle, setSignerAgreementButtonFontStyle] =
    useState<any>("");

  const [SignerAgreementButtonFontWeight, setSignerAgreementButtonFontWeight] =
    useState<any>("");

  const [
    SignerAgreementEmailBodyLinkFontFamily,
    setSignerAgreementEmailBodyLinkFontFamily,
  ] = useState<any>("Arial");
  const [
    SignerAgreementEmailBodyLinkFontSize,
    setSignerAgreementEmailBodyLinkFontSize,
  ] = useState<any>("16px");

  const [
    SignerAgreementEmailBodyLinkFontStyle,
    setSignerAgreementEmailBodyLinkFontStyle,
  ] = useState<any>("");

  const [
    SignerAgreementEmailBodyLinkFontWeight,
    setSignerAgreementEmailBodyLinkFontWeight,
  ] = useState<any>("");

  const [
    SignerAgreementEmailBodyLinkTextColor,
    setSignerAgreementEmailBodyLinkTextColor,
  ] = useState<any>("#007BFF");

  const resetSignerTemplate = () => {
    setBrandName("");
    // setsignerImage(easySignLogo);
    // setEmailImage(easySignLogo);
    setsignerImage(logoBase64);
    setEmailImage(logoBase64);

    setSignerAgreementBrandNameFontSize("36px");
    setSignerAgreementBrandNameFontFamily("Arial");
    setSignerAgreementBrandHeadingTextColor("#348eda");
    setSignerAgreementBrandNameFontStyle("");
    setSignerAgreementBrandNameFontWeight("700");
    setSignerAgreementEmailBodyBackgroundColor("#ffffff");
    setSignerAgreementEmailBodyTextColor("black");
    setSignerAgreementFontFamily("Arial");
    setSignerAgreementButtonText("Agree");
    setSignerAgreementButtonColor("#348eda");
    setSignerAgreementButtonTextColor("white");
    setSignerAgreementButtonFontSize("12.5px");

    setSignerAgreementBodyFontSize("16px");
    setSignerAgreementBodyFontStyle("");
    setSignerAgreementBodyFontWeight("");

    setSignerAgreementButtonFontStyle("");
    setSignerAgreementButtonFontWeight("");
    setSignerAgreementBtnFontFamily("Arial");

    setSignerAgreementEmailBodyLinkTextColor("#007BFF");

    setSignerAgreementEmailBodyLinkFontFamily("Arial");
    setSignerAgreementEmailBodyLinkFontSize("16px");
    setSignerAgreementEmailBodyLinkFontStyle("");
    setSignerAgreementEmailBodyLinkFontWeight("");

    setSignerAgreementBrandHeadingBackgroundColor("#b9cbfb");
  };

  const handleResetClick = () => {
    if (selectedTab === 0) {
      resetEmailTemplate();
      enqueueSnackbar(
        "Email settings have been successfully reset to default. Please save the changes.",
        {
          variant: "success",
        }
      );
    } else {
      resetSignerTemplate();
      enqueueSnackbar(
        "Signer Agreement settings have been successfully reset to default. Please save the changes.",
        {
          variant: "success",
        }
      );
    }
  };

  const [ResetConfirmPopup, setResetConfirmPopup] = useState(false);

  const handleResetYes = () => {
    handleResetClick();
    setResetConfirmPopup(false);
  };

  const handleResetNo = () => {
    setResetConfirmPopup(false);
  };

  const [SaveConfirmPopup, setSaveConfirmPopup] = useState(false);

  const handleSaveYes = () => {
    handleSaveApi();
  };

  const handleSaveNo = () => {
    setSaveConfirmPopup(false);
  };

  const handleSaveApi = async () => {
    setLoading(true);

    const payload = {
      email: {
        heading: {
          name: brandName,
          fontSize: BrandNameFontSize,
          fontColor: BrandHeadingTextColor,
          fontFamily: BrandNameFontFamily,
          fontStyle: BrandNameFontStyle,
          fontWeight: BrandNameFontWeight,
          backgroundColor: EmailBodyBackgroundColor,

          img: emailImage,
        },
        body: {
          // name: "",
          fontSize: EmailBodyFontSize,
          fontColor: EmailBodyTextColor,
          fontFamily: EmailFontFamily,
          fontStyle: EmailBodyFontStyle,
          fontWeight: EmailBodyFontWeight,
          backgroundColor: EmailBodyBackgroundColor,
        },
        button: {
          name: EmailButtonText,
          fontSize: buttonFontSize,
          fontColor: buttonTextColor,
          fontFamily: EmailBtnFontFamily,
          fontStyle: EmailButtonFontStyle,
          fontWeight: EmailButtonFontWeight,
          backgroundColor: EmailButtonBgColor,
        },
      },

      signerAgreement: {
        heading: {
          name: brandName,
          fontSize: SignerAgreementBrandNameFontSize,
          fontColor: SignerAgreementBrandHeadingTextColor,
          fontFamily: SignerAgreementBrandNameFontFamily,
          fontStyle: SignerAgreementBrandNameFontStyle,
          fontWeight: SignerAgreementBrandNameFontWeight,
          backgroundColor: SignerAgreementBrandHeadingBackgroundColor,

          img: signerImage,
        },
        body: {
          // name: "",
          fontSize: SignerAgreementBodyFontSize,
          fontColor: SignerAgreementEmailBodyTextColor,
          fontFamily: SignerAgreementFontFamily,
          fontStyle: SignerAgreementBodyFontStyle,
          fontWeight: SignerAgreementBodyFontWeight,
          backgroundColor: SignerAgreementEmailBodyBackgroundColor,

          // linkColor:SignerAgreementEmailBodyLinkTextColor,
        },
        button: {
          name: SignerAgreementbuttonText,
          fontSize: SignerAgreementbuttonFontSize,
          fontColor: SignerAgreementbuttonTextColor,
          fontFamily: SignerAgreementBtnFontFamily,
          fontStyle: SignerAgreementButtonFontStyle,
          fontWeight: SignerAgreementButtonFontWeight,
          backgroundColor: SignerAgreementbuttonColor,
        },

        link: {
          name: null,
          fontSize: SignerAgreementEmailBodyLinkFontSize,
          fontColor: SignerAgreementEmailBodyLinkTextColor,
          fontFamily: SignerAgreementEmailBodyLinkFontFamily,
          fontStyle: SignerAgreementEmailBodyLinkFontStyle,
          fontWeight: SignerAgreementEmailBodyLinkFontWeight,
          backgroundColor: null,
        },
      },
    };

    try {
      // Save the profile data
      await axios.post(
        `${baseURL}/branding/add-update-branding/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        payload,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      handleGetBrandingDetailsApi();

      enqueueSnackbar("Branding saved successfully", { variant: "success" });
      setSaveConfirmPopup(false);
    } catch (error) {
      enqueueSnackbar("Failed to save branding", { variant: "error" });
      setSaveConfirmPopup(false);
      handleGetBrandingDetailsApi();

      console.error(error);
    } finally {
      setLoading(false);
      setSaveConfirmPopup(false);
    }
  };

  interface BrandingDetails {
    email: {
      heading: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      body: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      button: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      link: {
        fontColor?: string;
      } | null;
    };
    signerAgreement: {
      heading: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      body: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      button: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
      link: {
        name: string;
        img: string | null;
        fontSize: string;
        fontColor: string;
        fontFamily: string;
        fontStyle: string;
        fontWeight: string;
        backgroundColor: string;
      };
    };
  }

  const handleSetStates = (resData: BrandingDetails) => {
    const { email, signerAgreement } = resData;

    /** --------- Email Data --------- */
    // Heading
    setBrandName(email.heading.name);
    setBrandNameFontFamily(email.heading.fontFamily);
    setBrandNameFontStyle(email.heading.fontStyle);
    setBrandNameFontWeight(email.heading.fontWeight);
    setBrandNameFontSize(email.heading.fontSize);
    setBrandHeadingTextColor(email.heading.fontColor);
    setEmailImage(email.heading.img || logoBase64);

    // Body
    setEmailBodyBackgroundColor(email.body.backgroundColor);
    setEmailBodyTextColor(email.body.fontColor);
    setEmailFontFamily(email.body.fontFamily);
    setEmailBodyFontSize(email.body.fontSize);
    setEmailBodyFontStyle(email.body.fontStyle);
    setEmailBodyFontWeight(email.body.fontWeight);

    // Button
    setEmailButtonText(email.button.name);
    setEmailButtonBgColor(email.button.backgroundColor);
    setButtonTextColor(email.button.fontColor);
    setButtonFontSize(email.button.fontSize);
    setEmailBtnFontFamily(email.button.fontFamily);
    setEmailButtonFontStyle(email.button.fontStyle);
    setEmailButtonFontWeight(email.button.fontWeight);

    /** --------- Signer Agreement Data --------- */
    // Heading
    setSignerAgreementBrandNameFontSize(signerAgreement.heading.fontSize);
    setSignerAgreementBrandNameFontFamily(signerAgreement.heading.fontFamily);
    setSignerAgreementBrandHeadingTextColor(signerAgreement.heading.fontColor);
    setSignerAgreementBrandNameFontStyle(signerAgreement.heading.fontStyle);
    setSignerAgreementBrandNameFontWeight(signerAgreement.heading.fontWeight);
    setsignerImage(signerAgreement.heading.img || logoBase64);

    setSignerAgreementBrandHeadingBackgroundColor(
      signerAgreement.heading.backgroundColor
    );

    // Body
    setSignerAgreementEmailBodyBackgroundColor(
      signerAgreement.body.backgroundColor
    );

    setSignerAgreementEmailBodyTextColor(signerAgreement.body.fontColor);
    setSignerAgreementFontFamily(signerAgreement.body.fontFamily);
    setSignerAgreementBodyFontSize(signerAgreement.body.fontSize);
    setSignerAgreementBodyFontStyle(signerAgreement.body.fontStyle);
    setSignerAgreementBodyFontWeight(signerAgreement.body.fontWeight);

    // Button
    setSignerAgreementButtonText(signerAgreement.button.name);
    setSignerAgreementButtonColor(signerAgreement.button.backgroundColor);
    setSignerAgreementButtonTextColor(signerAgreement.button.fontColor);
    setSignerAgreementButtonFontSize(signerAgreement.button.fontSize);
    setSignerAgreementBtnFontFamily(signerAgreement.button.fontFamily);
    setSignerAgreementButtonFontStyle(signerAgreement.button.fontStyle);
    setSignerAgreementButtonFontWeight(signerAgreement.button.fontWeight);

    // Link
    setSignerAgreementEmailBodyLinkTextColor(signerAgreement.link.fontColor);
    setSignerAgreementEmailBodyLinkFontFamily(signerAgreement.link.fontFamily);
    setSignerAgreementEmailBodyLinkFontSize(signerAgreement.link.fontSize);
    setSignerAgreementEmailBodyLinkFontStyle(signerAgreement.link.fontStyle);
    setSignerAgreementEmailBodyLinkFontWeight(signerAgreement.link.fontWeight);
  };

  const handleGetBrandingDetailsApi = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/branding/get-branding?userId=${sessionStorage.getItem(
          "userId"
        )}&emailId=`,
        {
          headers: {
            key: headerKey,
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      handleSetStates(response?.data);
    } catch (error) {
      enqueueSnackbar("Failed to get branding details", { variant: "error" });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBrandingDetailsApi();
  }, []);

  const convertToBase64 = (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result); // If it's a string, resolve with the Base64 string
        } else {
          reject(
            new Error("Failed to convert to Base64: result is not a string")
          );
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const accordionContent = [
    {
      tab: 0,
      options: [
        {
          title: "Email Brand Heading",
          content: [
            // <Box key="brand-image" mb={2}>
            //   <label
            //     htmlFor="brand-image"
            //     style={{ display: "block", marginBottom: "4px" }}
            //   >
            //     Email Brand Image
            //   </label>
            //   <input
            //     id="brand-image"
            //     type="file"
            //     accept="image/*"

            //     onChange={async (e) => {
            //       const file = e.target.files?.[0];
            //       if (file) {
            //         const validImageTypes = [
            //           "image/jpeg",
            //           "image/png",
            //           "image/gif",
            //           "image/webp",
            //         ];
            //         if (!validImageTypes.includes(file.type)) {
            //           enqueueSnackbar("Please select a valid image file.", {
            //             variant: "error",
            //           });
            //           return;
            //         }
            //         try {
            //           const base64 = await convertToBase64(file);

            //           setEmailImage(base64);
            //           setsignerImage(base64);

            //           // console.log("setEmailImage(base64)",  setEmailImage(base64));
            //           // console.log("setsignerImage(base64)", setsignerImage(base64));

            //         } catch (error) {
            //           console.error("Error converting image to Base64:", error);
            //           enqueueSnackbar("Failed to process image file.", {
            //             variant: "error",
            //           });
            //         }
            //       }
            //     }}
            //     style={{
            //       display: "block",
            //       width: "100%",
            //       marginBottom: "8px",
            //       cursor: "pointer",
            //     }}
            //   />

            //   <Box
            //     style={{
            //       width: "100px",
            //       height: "100px",
            //       borderRadius: "50%",
            //       overflow: "hidden",
            //       background: `url(${emailImage}) no-repeat center center`,
            //       backgroundSize: "cover",
            //       margin: "8px 0",
            //     }}
            //   />
            // </Box>,

            <Box key="brand-image" mb={2}>
              <label
                htmlFor="brand-image"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Image
              </label>
              <input
                id="brand-image"
                type="file"
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const validImageTypes = [
                      "image/jpeg",
                      "image/png",
                      "image/gif",
                      "image/webp",
                    ];
                    if (!validImageTypes.includes(file.type)) {
                      enqueueSnackbar("Please select a valid image file.", {
                        variant: "error",
                      });
                      return;
                    }
                    try {
                      const base64 = await convertToBase64(file);
                      if (base64) {
                        setEmailImage(base64);
                        setsignerImage(base64);
                      }
                    } catch (error) {
                      console.error("Error converting image to Base64:", error);
                      enqueueSnackbar("Failed to process image file.", {
                        variant: "error",
                      });
                    }
                  }
                }}
                style={{
                  display: "block",
                  width: "100%",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              />

              <Box
              // style={{
              //   width: "100px",
              //   height: "100px",
              //   borderRadius: "50%",
              //   overflow: "hidden",
              //   background: `url(${emailImage}) no-repeat center center`,
              //   backgroundSize: "cover",
              //   margin: "8px 0",
              // }}
              />
            </Box>,

            <Box key="brand-name" mb={2}>
              <label
                htmlFor="brand-name"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Name
              </label>
              <input
                id="brand-name"
                type="text"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="brand-heading-text-color" mb={2}>
              <label
                htmlFor="brand-heading-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Heading Text Color
              </label>
              <input
                id="brand-heading-text-color-picker"
                type="color"
                value={BrandHeadingTextColor}
                onChange={(e) => setBrandHeadingTextColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: BrandHeadingTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="brand-heading-font-family" mb={2}>
              <label
                htmlFor="brand-heading-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Heading Font Family
              </label>
              <select
                id="brand-heading-font-family-picker"
                value={BrandNameFontFamily}
                onChange={(e) => setBrandNameFontFamily(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="brand-name-font-size" mb={2}>
              <label
                htmlFor="brand-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Heading Font Size (in px)
              </label>
              <input
                id="brand-name-font-size"
                type="number"
                value={parseInt(BrandNameFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setBrandNameFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="brand-heading-font-style" mb={2}>
              <label
                htmlFor="brand-heading-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Heading Font Style
              </label>
              <select
                id="brand-heading-font-style-picker"
                value={BrandNameFontStyle}
                onChange={(e) => setBrandNameFontStyle(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,
            <Box key="brand-heading-font-weight" mb={2}>
              <label
                htmlFor="brand-heading-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Brand Heading Font Weight
              </label>
              <select
                id="brand-heading-font-weight-picker"
                value={BrandNameFontWeight}
                onChange={(e) => setBrandNameFontWeight(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },

        {
          title: "Email Body",
          content: [
            <Box key="email-body-bg-color" mb={2}>
              <label
                htmlFor="email-body-bg-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Background Color
              </label>
              <input
                id="email-body-bg-color-picker"
                type="color"
                value={EmailBodyBackgroundColor}
                onChange={(e) => setEmailBodyBackgroundColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: EmailBodyBackgroundColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="email-body-text-color" mb={2}>
              <label
                htmlFor="email-body-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Text Color
              </label>
              <input
                id="email-body-text-color-picker"
                type="color"
                value={EmailBodyTextColor}
                onChange={(e) => setEmailBodyTextColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: EmailBodyTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,
            <Box key="email-body-font-family" mb={2}>
              <label
                htmlFor="email-body-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Font Family
              </label>
              <select
                id="email-body-font-family-picker"
                value={EmailFontFamily}
                onChange={(e) => setEmailFontFamily(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="email-body-font-size" mb={2}>
              <label
                htmlFor="email-body-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Body Font Size (in px)
              </label>
              <input
                id="email-body-font-size"
                type="number"
                value={parseInt(EmailBodyFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setEmailBodyFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="email-body-font-style" mb={2}>
              <label
                htmlFor="email-body-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Body Font Style
              </label>
              <select
                id="email-body-font-style-picker"
                value={EmailBodyFontStyle}
                onChange={(e) => setEmailBodyFontStyle(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,

            <Box key="email-body-font-weight" mb={2}>
              <label
                htmlFor="email-body-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Email Body Font Weight
              </label>
              <select
                id="email-body-font-weight-picker"
                value={EmailBodyFontWeight}
                onChange={(e) => setEmailBodyFontWeight(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },

        {
          title: "Navigation Button",
          content: [
            <Box key="email-btn-name" mb={2}>
              <label
                htmlFor="email-btn-name"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Text Name
              </label>
              <input
                id="email-btn-name"
                type="text"
                value={EmailButtonText}
                onChange={(e) => setEmailButtonText(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="email-btn-bg-color" mb={2}>
              <label
                htmlFor="email-btn-bg-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Background Color
              </label>
              <input
                id="email-btn-bg-color-picker"
                type="color"
                value={EmailButtonBgColor}
                onChange={(e) => setEmailButtonBgColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: EmailButtonBgColor,
                  cursor: "pointer",
                }}
              />
            </Box>,
            <Box key="email-btn-text-color" mb={2}>
              <label
                htmlFor="email-btn-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Text Color
              </label>
              <input
                id="email-btn-text-color-picker"
                type="color"
                value={buttonTextColor}
                onChange={(e) => setButtonTextColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: buttonTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="brand-btn-font-family" mb={2}>
              <label
                htmlFor="brand-btn-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Family
              </label>
              <select
                id="brand-btn-font-family-picker"
                value={EmailBtnFontFamily}
                onChange={(e) => setEmailBtnFontFamily(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="email-btn-name-font-size" mb={2}>
              <label
                htmlFor="email-btn-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Size (in px)
              </label>
              <input
                id="email-btn-name-font-size"
                type="number"
                value={parseInt(buttonFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setButtonFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="email-btn-font-style" mb={2}>
              <label
                htmlFor="email-btn-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Style
              </label>
              <select
                id="email-btn-font-style-picker"
                value={EmailButtonFontStyle}
                onChange={(e) => setEmailButtonFontStyle(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,

            <Box key="email-btn-font-weight" mb={2}>
              <label
                htmlFor="email-btn-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Weight
              </label>
              <select
                id="email-btn-font-weight-picker"
                value={EmailButtonFontWeight}
                onChange={(e) => setEmailButtonFontWeight(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },
      ],
    },

    {
      tab: 1,
      options: [
        {
          title: "Signer Brand Heading",
          content: [
            // <Box key="signer-brand-image" mb={2}>
            //   <label
            //     htmlFor="signer-brand-image"
            //     style={{ display: "block", marginBottom: "4px" }}
            //   >
            //     Signer Brand Image
            //   </label>
            //   <input
            //     id="signer-brand-image"
            //     type="file"
            //     accept="image/*"

            //     onChange={async (e) => {
            //       const file = e.target.files?.[0];
            //       if (file) {
            //         const validImageTypes = [
            //           "image/jpeg",
            //           "image/png",
            //           "image/gif",
            //           "image/webp",
            //         ];
            //         if (!validImageTypes.includes(file.type)) {
            //           enqueueSnackbar("Please select a valid image file.", {
            //             variant: "error",
            //           });
            //           return;
            //         }

            //         try {
            //           const base64 = await convertToBase64(file);
            //           setsignerImage(base64);
            //           setEmailImage(base64); // If this is required for other functionality
            //         } catch (error) {
            //           console.error("Error converting image to Base64:", error);
            //           enqueueSnackbar("Failed to process image file.", {
            //             variant: "error",
            //           });
            //         }
            //       }
            //     }}
            //     style={{
            //       display: "block",
            //       width: "100%",
            //       marginBottom: "8px",
            //       cursor: "pointer",
            //     }}
            //   />

            //   <Box
            //     style={{
            //       width: "100px",
            //       height: "100px",
            //       borderRadius: "50%",
            //       overflow: "hidden",
            //       background: `url(${signerImage}) no-repeat center center`,
            //       backgroundSize: "cover",
            //       margin: "8px 0",
            //     }}
            //   />

            // </Box>,

            <Box key="signer-brand-image" mb={2}>
              <label
                htmlFor="signer-brand-image"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Image
              </label>
              <input
                id="signer-brand-image"
                type="file"
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const validImageTypes = [
                      "image/jpeg",
                      "image/png",
                      "image/gif",
                      "image/webp",
                    ];
                    if (!validImageTypes.includes(file.type)) {
                      enqueueSnackbar("Please select a valid image file.", {
                        variant: "error",
                      });
                      return;
                    }
                    try {
                      const base64 = await convertToBase64(file);
                      if (base64) {
                        setEmailImage(base64);
                        setsignerImage(base64);
                      }
                    } catch (error) {
                      console.error("Error converting image to Base64:", error);
                      enqueueSnackbar("Failed to process image file.", {
                        variant: "error",
                      });
                    }
                  }
                }}
                style={{
                  display: "block",
                  width: "100%",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              />

              {/* <Box
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  background: `url(${signerImage}) no-repeat center center`,
                  backgroundSize: "cover",
                  margin: "8px 0",
                }}
              /> */}
            </Box>,

            <Box key="brand-name" mb={2}>
              <label
                htmlFor="brand-name"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Name
              </label>
              <input
                id="brand-name"
                type="text"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-brand-heading-bg-color" mb={2}>
              <label
                htmlFor="signer-brand-heading-bg-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Background Color
              </label>
              <input
                id="signer-brand-heading-bg-color-picker"
                type="color"
                value={SignerAgreementBrandHeadingBackgroundColor}
                onChange={(e) =>
                  setSignerAgreementBrandHeadingBackgroundColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementBrandHeadingBackgroundColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="signer-brand-heading-text-color" mb={2}>
              <label
                htmlFor="signer-brand-heading-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Heading Text Color
              </label>
              <input
                id="signer-brand-heading-text-color-picker"
                type="color"
                value={SignerAgreementBrandHeadingTextColor}
                onChange={(e) =>
                  setSignerAgreementBrandHeadingTextColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementBrandHeadingTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="signer-brand-heading-font-family" mb={2}>
              <label
                htmlFor="signer-brand-heading-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Heading Font Family
              </label>
              <select
                id="signer-brand-heading-font-family-picker"
                value={SignerAgreementBrandNameFontFamily}
                onChange={(e) =>
                  setSignerAgreementBrandNameFontFamily(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="signer-brand-name-font-size" mb={2}>
              <label
                htmlFor="signer-brand-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Heading Font Size (in px)
              </label>
              <input
                id="signer-brand-name-font-size"
                type="number"
                value={parseInt(SignerAgreementBrandNameFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setSignerAgreementBrandNameFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-brand-heading-font-style" mb={2}>
              <label
                htmlFor="signer-brand-heading-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Heading Font Style
              </label>
              <select
                id="signer-brand-heading-font-style-picker"
                value={SignerAgreementBrandNameFontStyle}
                onChange={(e) =>
                  setSignerAgreementBrandNameFontStyle(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">None</option>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,
            <Box key="signer-brand-heading-font-weight" mb={2}>
              <label
                htmlFor="signer-brand-heading-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Brand Heading Font Weight
              </label>
              <select
                id="signer-brand-heading-font-weight-picker"
                value={SignerAgreementBrandNameFontWeight}
                onChange={(e) =>
                  setSignerAgreementBrandNameFontWeight(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">Default</option>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },

        {
          title: "Signer Agreement Body",
          content: [
            <Box key="signer-email-body-bg-color" mb={2}>
              <label
                htmlFor="signer-email-body-bg-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Background Color
              </label>
              <input
                id="signer-email-body-bg-color-picker"
                type="color"
                value={SignerAgreementEmailBodyBackgroundColor}
                onChange={(e) =>
                  setSignerAgreementEmailBodyBackgroundColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementEmailBodyBackgroundColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="signer-body-text-color" mb={2}>
              <label
                htmlFor="signer-body-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Text Color
              </label>
              <input
                id="signer-body-text-color-picker"
                type="color"
                value={SignerAgreementEmailBodyTextColor}
                onChange={(e) =>
                  setSignerAgreementEmailBodyTextColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementEmailBodyTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            // <Box key="signer-body-link-text-color" mb={2}>
            //   <label
            //     htmlFor="signer-body-link-text-color-picker"
            //     style={{ display: "block", marginBottom: "4px" }}
            //   >
            //     Signer Body Link Text Color
            //   </label>
            //   <input
            //     id="signer-body-link-text-color-picker"
            //     type="color"
            //     value={SignerAgreementEmailBodyLinkTextColor}
            //     onChange={(e) =>
            //       setSignerAgreementEmailBodyLinkTextColor(e.target.value)
            //     }
            //     style={{
            //       display: "block",
            //       width: "100%",
            //       height: "40px",
            //       border: "1px solid #ccc",
            //       borderRadius: "4px",
            //       padding: "2px",
            //       boxSizing: "border-box",
            //       backgroundColor: SignerAgreementEmailBodyLinkTextColor,
            //       cursor: "pointer",
            //     }}
            //   />
            // </Box>,

            <Box key="signer-body-font-family" mb={2}>
              <label
                htmlFor="signer-body-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Font Family
              </label>
              <select
                id="signer-body-font-family-picker"
                value={SignerAgreementFontFamily}
                onChange={(e) => setSignerAgreementFontFamily(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="signer-body-name-font-size" mb={2}>
              <label
                htmlFor="signer-body-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Font Size (in px)
              </label>
              <input
                id="signer-body-name-font-size"
                type="number"
                value={parseInt(SignerAgreementBodyFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setSignerAgreementBodyFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-body-font-style" mb={2}>
              <label
                htmlFor="signer-body-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Font Style
              </label>
              <select
                id="signer-body-font-style-picker"
                value={SignerAgreementBodyFontStyle}
                onChange={(e) =>
                  setSignerAgreementBodyFontStyle(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">None</option>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,

            <Box key="signer-body-font-weight" mb={2}>
              <label
                htmlFor="signer-body-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Body Font Weight
              </label>
              <select
                id="signer-body-font-weight-picker"
                value={SignerAgreementBodyFontWeight}
                onChange={(e) =>
                  setSignerAgreementBodyFontWeight(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">Default</option>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },

        {
          title: "Signer Agree Button",
          content: [
            <Box key="signer-btn-name" mb={2}>
              <label
                htmlFor="signer-btn-name"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Text Name
              </label>
              <input
                id="signer-btn-name"
                type="text"
                value={SignerAgreementbuttonText}
                onChange={(e) => setSignerAgreementButtonText(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-btn-bg-color" mb={2}>
              <label
                htmlFor="signer-btn-bg-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Background Color
              </label>
              <input
                id="signer-btn-bg-color-picker"
                type="color"
                value={SignerAgreementbuttonColor}
                onChange={(e) => setSignerAgreementButtonColor(e.target.value)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementbuttonColor,
                  cursor: "pointer",
                }}
              />
            </Box>,
            <Box key="signer-btn-text-color" mb={2}>
              <label
                htmlFor="signer-btn-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Text Color
              </label>
              <input
                id="signer-btn-text-color-picker"
                type="color"
                value={SignerAgreementbuttonTextColor}
                onChange={(e) =>
                  setSignerAgreementButtonTextColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementbuttonTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="signer-btn-font-family" mb={2}>
              <label
                htmlFor="signer-btn-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Family
              </label>
              <select
                id="signer-btn-font-family-picker"
                value={SignerAgreementBtnFontFamily}
                onChange={(e) =>
                  setSignerAgreementBtnFontFamily(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="signer-btn-name-font-size" mb={2}>
              <label
                htmlFor="signer-btn-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Size (in px)
              </label>
              <input
                id="signer-btn-name-font-size"
                type="number"
                value={parseInt(SignerAgreementbuttonFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setSignerAgreementButtonFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-btn-font-style" mb={2}>
              <label
                htmlFor="signer-btn-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Style
              </label>
              <select
                id="signer-btn-font-style-picker"
                value={SignerAgreementButtonFontStyle}
                onChange={(e) =>
                  setSignerAgreementButtonFontStyle(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">None</option>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,

            <Box key="signer-btn-font-weight" mb={2}>
              <label
                htmlFor="signer-btn-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Button Font Weight
              </label>
              <select
                id="signer-btn-font-weight-picker"
                value={SignerAgreementButtonFontWeight}
                onChange={(e) =>
                  setSignerAgreementButtonFontWeight(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">Default</option>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },

        {
          title: "Signer Agreement Link",
          content: [
            <Box key="signer-body-link-text-color" mb={2}>
              <label
                htmlFor="signer-body-link-text-color-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Body Link Text Color
              </label>
              <input
                id="signer-body-link-text-color-picker"
                type="color"
                value={SignerAgreementEmailBodyLinkTextColor}
                onChange={(e) =>
                  setSignerAgreementEmailBodyLinkTextColor(e.target.value)
                }
                style={{
                  display: "block",
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  boxSizing: "border-box",
                  backgroundColor: SignerAgreementEmailBodyLinkTextColor,
                  cursor: "pointer",
                }}
              />
            </Box>,

            <Box key="signer-body-link-font-family" mb={2}>
              <label
                htmlFor="signer-body-link-font-family-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Body Link Font Family
              </label>
              <select
                id="signer-body-link-font-family-picker"
                value={SignerAgreementEmailBodyLinkFontFamily}
                onChange={(e) =>
                  setSignerAgreementEmailBodyLinkFontFamily(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Georgia">Georgia</option>
                <option value="Courier New">Courier New</option>
              </select>
            </Box>,

            <Box key="signer-body-link-name-font-size" mb={2}>
              <label
                htmlFor="signer-body-link-name-font-size"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Body Link Font Size (in px)
              </label>
              <input
                id="signer-body-link-name-font-size"
                type="number"
                value={parseInt(SignerAgreementEmailBodyLinkFontSize, 10)} // Display numeric value without "px"
                onChange={(e) => {
                  const value = e.target.value;
                  setSignerAgreementEmailBodyLinkFontSize(`${value}px`); // Add "px" to the value
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                }}
              />
            </Box>,

            <Box key="signer-body-link-font-style" mb={2}>
              <label
                htmlFor="signer-body-link-font-style-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Body Link Font Style
              </label>
              <select
                id="signer-body-link-font-style-picker"
                value={SignerAgreementEmailBodyLinkFontStyle}
                onChange={(e) =>
                  setSignerAgreementEmailBodyLinkFontStyle(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">None</option>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
              </select>
            </Box>,

            <Box key="signer-body-link-font-weight" mb={2}>
              <label
                htmlFor="signer-body-link-font-weight-picker"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Signer Body Link Font Weight
              </label>
              <select
                id="signer-body-link-font-weight-picker"
                value={SignerAgreementEmailBodyLinkFontWeight}
                onChange={(e) =>
                  setSignerAgreementEmailBodyLinkFontWeight(e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              >
                <option value="">Default</option>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="lighter">Lighter</option>
                <option value="100">100 (Thin)</option>
                <option value="200">200 (Extra Light)</option>
                <option value="300">300 (Light)</option>
                <option value="400">400 (Normal)</option>
                <option value="500">500 (Medium)</option>
                <option value="600">600 (Semi Bold)</option>
                <option value="700">700 (Bold)</option>
                <option value="800">800 (Extra Bold)</option>
                <option value="900">900 (Black)</option>
              </select>
            </Box>,
          ],
        },
      ],
    },
  ];

  const activeAccordion = accordionContent.find(
    (item) => item.tab === selectedTab
  );

  return (
    <>
      <div style={{ overflowY: "hidden" }}>
        <Headers />

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            zIndex="100000"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              width="100%"
              // height="100vh"
              // minHeight="auto"
              minHeight="calc(100vh - 80px)"
              maxHeight="auto"
              sx={{ overflow: "hidden" }}
            >
              {/* Sidebar */}
              <Box
                width="30%"
                bgcolor="#f5f5f5"
                p={2}
                borderRight="1px solid #ddd"
                sx={{
                  overflowY: "auto",
                  height: "calc(100vh - 80px)",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: "#3f51b5",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Custom Branding
                </Typography>

                {activeAccordion?.options.map((option, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {/* <Typography >{option.title}</Typography> */}
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          // color: "#1a237e",
                          color: "black",

                          // textShadow: "2px 2px 4px rgba(255, 235, 59, 0.6)",
                          textAlign: "center",
                          // textTransform: "uppercase",
                        }}
                      >
                        {option.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Array.isArray(option.content) ? (
                        option.content.map((item, idx) => (
                          <Box key={idx}>{item}</Box>
                        ))
                      ) : (
                        <Typography>{option.content}</Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>

              {/* Main Content */}
              <Box
                width="70%"
                p={2}
                display="flex"
                flexDirection="column"
                sx={{ overflowY: "hidden" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {/* Tabs Section */}
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                  >
                    <Tab label="Email Template" />
                    <Tab label="Signer Agreement" />
                  </Tabs>

                  {/* Buttons Section */}
                  <Box display="flex" alignItems="center">
                    <Button
                      onClick={() => setResetConfirmPopup(true)}
                      sx={{
                        marginLeft: "8px",
                        zIndex: 10,
                        position: "relative",
                        color: "black",
                        textTransform: "none",
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "4px 12px",
                      }}
                      startIcon={<DefaultIcon />}
                    >
                      {selectedTab === 0
                        ? "Default Email Template"
                        : "Default Signer Template"}
                    </Button>

                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "green",
                        color: "white",
                        marginLeft: "15px",
                      }}
                      onClick={() => setSaveConfirmPopup(true)}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>

                {/* Tab Content */}
                <Box flex={1} mt={2} mb={2} color="black">
                  {selectedTab === 0 && (
                    <>
                      <EmailTemplate
                        title="Document Review Notification"
                        customMessage="Please review the attached documents before the deadline."
                        mainMessage="You have been assigned a few documents to review and sign. Please use the link below to access."
                        redirectUrl="https://example.com/document"
                        senderName={
                          sessionStorage.getItem("userName") || "Default User"
                        }
                        recieverName="(Signer's Name)"
                        senderEmail={
                          sessionStorage.getItem("userEmail") ||
                          "defaultUser.eamil.com"
                        }
                        EmailBodyBackgroundColor={EmailBodyBackgroundColor}
                        EmailBodyTextColor={EmailBodyTextColor}
                        fontStyle={EmailFontFamily}
                        emailImage={emailImage}
                        brandName={brandName}
                        BrandNameFontSize={BrandNameFontSize}
                        BrandNameFontFamily={BrandNameFontFamily}
                        BrandHeadingTextColor={BrandHeadingTextColor}
                        BrandNameFontStyle={BrandNameFontStyle}
                        BrandNameFontWeight={BrandNameFontWeight}
                        EmailBodyFontSize={EmailBodyFontSize}
                        EmailBodyFontStyle={EmailBodyFontStyle}
                        EmailBodyFontWeight={EmailBodyFontWeight}
                        EmailButtonFontStyle={EmailButtonFontStyle}
                        EmailButtonFontWeight={EmailButtonFontWeight}
                        buttonColor={EmailButtonBgColor}
                        buttonTextColor={buttonTextColor}
                        buttonFontSize={buttonFontSize}
                        buttonText={EmailButtonText}
                        EmailBtnFontFamily={EmailBtnFontFamily}
                      />
                    </>
                  )}
                  {selectedTab === 1 && (
                    <>
                      <SignerAgreementTemplate
                        SignerAgreementEmailBodyBackgroundColor={
                          SignerAgreementEmailBodyBackgroundColor
                        }
                        SignerAgreementEmailBodyTextColor={
                          SignerAgreementEmailBodyTextColor
                        }
                        SignerAgreementfontStyle={SignerAgreementFontFamily}
                        SignerAgreementbuttonColor={SignerAgreementbuttonColor}
                        SignerAgreementbuttonTextColor={
                          SignerAgreementbuttonTextColor
                        }
                        SignerAgreementbuttonFontSize={
                          SignerAgreementbuttonFontSize
                        }
                        SignerAgreementbuttonText={SignerAgreementbuttonText}
                        signerImage={signerImage}
                        brandName={brandName}
                        SignerAgreementBrandNameFontSize={
                          SignerAgreementBrandNameFontSize
                        }
                        SignerAgreementBrandNameFontFamily={
                          SignerAgreementBrandNameFontFamily
                        }
                        SignerAgreementBrandHeadingTextColor={
                          SignerAgreementBrandHeadingTextColor
                        }
                        SignerAgreementBrandNameFontStyle={
                          SignerAgreementBrandNameFontStyle
                        }
                        SignerAgreementBrandNameFontWeight={
                          SignerAgreementBrandNameFontWeight
                        }
                        SignerAgreementBodyFontSize={
                          SignerAgreementBodyFontSize
                        }
                        SignerAgreementBodyFontStyle={
                          SignerAgreementBodyFontStyle
                        }
                        SignerAgreementBodyFontWeight={
                          SignerAgreementBodyFontWeight
                        }
                        SignerAgreementButtonFontStyle={
                          SignerAgreementButtonFontStyle
                        }
                        SignerAgreementButtonFontWeight={
                          SignerAgreementButtonFontWeight
                        }
                        SignerAgreementBtnFontFamily={
                          SignerAgreementBtnFontFamily
                        }
                        SignerAgreementEmailBodyLinkTextColor={
                          SignerAgreementEmailBodyLinkTextColor
                        }
                        SignerAgreementEmailBodyLinkFontSize={
                          SignerAgreementEmailBodyLinkFontSize
                        }
                        SignerAgreementEmailBodyLinkFontFamily={
                          SignerAgreementEmailBodyLinkFontFamily
                        }
                        SignerAgreementEmailBodyLinkFontStyle={
                          SignerAgreementEmailBodyLinkFontStyle
                        }
                        SignerAgreementEmailBodyLinkFontWeight={
                          SignerAgreementEmailBodyLinkFontWeight
                        }
                        SignerAgreementBrandHeadingBackgroundColor={
                          SignerAgreementBrandHeadingBackgroundColor
                        }
                      />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </div>

      {ResetConfirmPopup && (
        <ConfirmationPopup
          open={ResetConfirmPopup}
          title="Are you sure?"
          message="Do you really want to reset branding to the default settings of EasySign?"
          onYes={handleResetYes}
          onNo={handleResetNo}
          onClose={() => setResetConfirmPopup(false)}
        />
      )}

      {SaveConfirmPopup && (
        <ConfirmationPopup
          open={SaveConfirmPopup}
          title="Confirmation Required"
          message="Are you sure you want to save the branding changes?"
          onYes={handleSaveYes}
          onNo={handleSaveNo}
          onClose={() => setSaveConfirmPopup(false)}
        />
      )}
    </>
  );
};
