import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { FaFileDownload, FaRedo, FaSave, FaTrash } from "react-icons/fa";
import ManageContext from "../../context/manage/manage.context";
import { useSnackbar } from "notistack";
import { SaveAsTemplatePopup } from "./SaveAsTemplatePopup";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Button,
} from "@mui/material";
import { FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import styles from "../../styles/Manage.module.css";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface AllDocumentsTableProps {
  allDocs: any[];
}

export const AllDocumentsTable: React.FC<AllDocumentsTableProps> = ({
  allDocs,
}) => {
  const navigate = useNavigate();
  const { setCurrentDocumentHeaderId } = useContext(ManageContext);
  const [allDocumentData, setAllDocumentData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [SaveAsTemplate, setSaveAsTemplate] = useState(false);
  const [sortDirection, setSortDirection] = useState<boolean>(true); // true for ascending, false for descending

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const updatedDocs = allDocs?.map((doc) => ({
      Subject: doc?.envelopeName,
      TotalDocument: doc?.totalDocuments,
      SentOn: doc?.sentOnMail,
      Status: doc?.signerStatus,
      LastChanges: doc?.lastUpdated,
      HeaderId: doc?.documentHeaderId,
      senderStatus: doc?.senderStatus,
    }));
    setAllDocumentData(updatedDocs);
  }, [allDocs]);

  const caseSort = (rowA: any, rowB: any) => {
    const a = rowA.LastChanges;
    const b = rowB.LastChanges;
    return a > b ? 1 : b > a ? -1 : 0;
  };

  const handleDeleteDocument = (documentHeaderId: string) => {
    console.log(`Deleting document with Header ID: ${documentHeaderId}`);
  };

  const handleSign = (documentHeaderId: string) => {
    setCurrentDocumentHeaderId(documentHeaderId);
    navigate("/documentDetails");
  };

  const handleSignPackageInfo = (documentHeaderId: string) => {
    setCurrentDocumentHeaderId(documentHeaderId);
    navigate(`/documentDetails?id=${documentHeaderId}`);
  };

  const getAuditData = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-audit-document-wise?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "auditTrail.pdf");
    } catch (err) {
      console.error("Failed to download audit report", err);
    }
  };

  const getSignedDoc = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-completed-document?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "signedDoc.zip");
    } catch (err) {
      console.error("Failed to download signed document", err);
    }
  };

  const handleResendDocument = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/upload/resend-documents-to-signers/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
          body: JSON.stringify({ documentHeaderId }),
        }
      );

      if (res.ok) {
        enqueueSnackbar("Document resent successfully", { variant: "success" });
      } else {
        const errorData = await res.json();
        enqueueSnackbar(
          `Error: ${errorData.message || "Failed to resend document"}`,
          { variant: "error" }
        );
      }
    } catch (err) {
      console.error("Failed to resend document", err);
      enqueueSnackbar("An error occurred while resending the document", {
        variant: "error",
      });
    }
  };

  const handleSaveAsTemplate = () => {
    setSaveAsTemplate(true);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleSort = () => {
    const sortedData = [...allDocumentData].sort((a, b) => {
      if (sortDirection) {
        return moment(a.LastChanges).isBefore(b.LastChanges) ? -1 : 1;
      } else {
        return moment(a.LastChanges).isBefore(b.LastChanges) ? 1 : -1;
      }
    });
    setAllDocumentData(sortedData);
    setSortDirection(!sortDirection);
  };

  return (
    <>
      {allDocumentData.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "#666",
          }}
        >
          No documents available.
        </div>
      ) : (
        <>
          <TableContainer className="shadow-md sm:rounded-lg">
            <Table style={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      width: "30%",
                    }}
                  >
                    SUBJECT
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    COUNT
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    LAST CHANGED
                    <IconButton onClick={handleSort} size="small">
                      {sortDirection ? <ArrowUpward /> : <ArrowDownward />}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    ACTIONS
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    MENU
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDocumentData?.map((row) => (
                  <TableRow key={row.HeaderId}>
                    <TableCell
                      style={{
                        wordBreak: "break-word",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      {row.Subject}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "13px" }}
                      align="center"
                    >
                      {row.TotalDocument}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "13px" }}
                      align="center"
                    >
                      {row.Status}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "break-word", fontSize: "13px" }}
                      align="center"
                    >
                      {moment(row.LastChanges).format("D MMM YY, h:mm A")}
                    </TableCell>
                    <TableCell align="center">
                      {row.Status !== "completed" &&
                      row.senderStatus !== "completed" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={styles.btn}
                          onClick={() => handleSign(row.HeaderId)}
                        >
                          Start
                        </Button>
                      ) : row.Status !== "completed" &&
                        row.senderStatus === "completed" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={styles.btn}
                          onClick={() => handleSignPackageInfo(row.HeaderId)}
                        >
                          Package Info
                        </Button>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={(event) => handleMenuOpen(event, row)}
                      >
                        <FaEllipsisV color="black" size={20} />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) &&
                          selectedRow?.HeaderId === row.HeaderId
                        }
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: { width: "250px" },
                        }}
                      >
                        {row.Status === "completed" && (
                          <>
                            <MenuItem
                              onClick={() => {
                                getAuditData(row.HeaderId);
                                handleMenuClose();
                              }}
                            >
                              <FaFileDownload style={{ marginRight: "10px" }} />{" "}
                              {/* Download Audit Report */}
                              Download Certificate
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                getSignedDoc(row.HeaderId);
                                handleMenuClose();
                              }}
                            >
                              <FaFileDownload style={{ marginRight: "10px" }} />{" "}
                              Download Signed Document
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                getSignedDoc(row.HeaderId);
                                getAuditData(row.HeaderId);
                                handleMenuClose();
                              }}
                            >
                              <FaFileDownload style={{ marginRight: "10px" }} />{" "}
                              {/* Download Doc & Audit */}
                              Download Doc & Certificate
                            </MenuItem>
                          </>
                        )}
                        {row.Status !== "completed" &&
                          row.senderStatus === "completed" && (
                            <MenuItem
                              onClick={() => {
                                handleResendDocument(row.HeaderId);
                                handleMenuClose();
                              }}
                            >
                              <FaRedo style={{ marginRight: "10px" }} /> Resend
                              Document
                            </MenuItem>
                          )}
                        {row.Status === "completed" && (
                          <MenuItem
                            onClick={() => {
                              handleSaveAsTemplate();
                            }}
                          >
                            <FaSave style={{ marginRight: "10px" }} /> Save As
                            Template
                          </MenuItem>
                        )}
                        {/* <MenuItem
                          onClick={() => {
                            handleDeleteDocument(row.HeaderId);
                            handleMenuClose();
                          }}
                        >
                          <FaTrash style={{ marginRight: "10px" }} /> Delete
                          Document
                        </MenuItem> */}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <SaveAsTemplatePopup
        open={SaveAsTemplate}
        onClose={() => {
          setSaveAsTemplate(false);
          handleMenuClose();
        }}
        selectedRow={selectedRow}
      />
    </>
  );
};
