import axios from "axios";
import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { SignatrueReducer } from "./Signature.reducer";

export interface initialStateType {
  signImage: string;
  signKey: string;
  initialImage: string;
  initialKey: string;
  types: string;
  isDefault: boolean;
  isDeleted: boolean;
  userId: any;
  companyId: any;
  loading: boolean;
  signText: string;
  initialText: string;
  fontId: string;
  userName: string;
}

export const initialState: initialStateType = {
  signImage: "",
  signKey: "",
  initialImage: "",
  initialKey: "",
  fontId: "",
  types: "",
  isDefault: true,
  isDeleted: false,
  userId: 1,
  companyId: 1,
  loading: true,
  signText: "",
  initialText: "",
  userName: "",
};

const SignatureContext = createContext(initialState);

export default SignatureContext;

interface SignatureProviderProps {
  children: React.ReactNode;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const SignatureProvider: React.FC<SignatureProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(SignatrueReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const setSignImage = async (data: any) => {
    // console.log({ data });
    dispatch({
      type: "SET_SIGNATURE",
    });
    await fetch(`${baseURL}/sign/add-sign/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: `${headerKey}`,
        Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.text())
      .then(async (res) => {
        console.log({ res });

        enqueueSnackbar("Signature Added Successfully", {
          variant: "success",
        });
        await getSignImage();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getSignImage = async () => {
    console.log("Company ID :", sessionStorage.getItem("companyId"));
    await fetch(
      `${baseURL}/sign/get-sign/${sessionStorage.getItem(
        "userId"
      )}/${sessionStorage.getItem("companyId")}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log({ res: res[res.length - 1] });
        if (!res.err) {
          dispatch({
            type: "GET_SIGNATURE",
            payload: res[res.length - 1],
          });
          // enqueueSnackbar("Something went wrong", { variant: "error" });
        } else {
          dispatch({
            type: "UNSET_LOADING",
          });
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: "UNSET_LOADING",
        });
        // enqueueSnackbar("Something went wrong", { variant: "error" });
        //  TODO temp fix: need to check later
      });
  };
  const getUserInfo = async () => {
    axios
      .get(
        `${baseURL}/auth/get-user-details/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("userProfileDetails", JSON.stringify(res.data));

        if (res.data) {
          sessionStorage.setItem("userName", res.data?.name);
          dispatch({
            type: "SET_NAME",
            payload: res.data,
          });
          sessionStorage.setItem("userEmail", res.data?.email);
        }
      });
  };

  useEffect(() => {
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    if (userId && companyId) {
      getSignImage();
    }
  }, []);

  const [saveCount, setSaveCount] = useState<string>(
    sessionStorage.getItem("profileSaveCount") || ""
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newSaveCount = sessionStorage.getItem("profileSaveCount") || "";
      if (newSaveCount !== saveCount) {
        setSaveCount(newSaveCount);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [saveCount]);

  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    const companyId = sessionStorage.getItem("companyId");

    if (userId && companyId && saveCount) {
      getSignImage();
      getUserInfo();
    }
  }, [saveCount]);

  return (
    <SignatureContext.Provider
      value={{ ...state, setSignImage, getSignImage, getUserInfo } as any}
    >
      {children}
    </SignatureContext.Provider>
  );
};
