import React from "react";
import { Box, Button, Typography, Modal, Backdrop } from "@mui/material";

interface ConfirmationPopupProps {
  open: boolean; // To control the visibility of the popup
  title: string; // Title of the popup
  message: string; // Message to display inside the popup
  onYes: () => void; // Callback for the 'Yes' button
  onNo: () => void; // Callback for the 'No' button
  onClose: () => void; // Callback for closing the popup
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  open,
  title,
  message,
  onYes,
  onNo,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="confirmation-title">
      {/* Wrapping Backdrop and Box in a div */}
      <div>
        <Backdrop
          open={open}
          sx={{
            zIndex: 1,
            backdropFilter: "blur(2px)", // Apply the blur effect
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Blackish background
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Increased width of the popup
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            zIndex: 2, // Ensure popup is above the backdrop
          }}
        >
          <Typography
            id="confirmation-title"
            variant="h6"
            gutterBottom
            sx={{ color: "#1976d2", fontWeight: "bold" }} // Custom color and bold text for the title
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            sx={{ color: "#555555" }} // Custom color for the message
          >
            {message}
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="outlined" color="secondary" onClick={onNo}>
              No
            </Button>
            <Button variant="contained" color="primary" onClick={onYes}>
              Yes
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
