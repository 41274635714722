import { Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import { Search, StyledInputBase } from "./helpers/SearchBox";
import { Headers } from "./layouts/Headers";
import TemplateSideBar from "./layouts/TemplateSideBar";
import ManageContext from "../context/manage/manage.context";
import SignatureContext from "../context/signature/Signature.context";
import { useSearchParams } from "react-router-dom";
import { AllTemplateDocumentsTable } from "./Template/AllTemplateDocumentsTable";

interface ManageProps {}

export const Template: React.FC<ManageProps> = ({}) => {
  const [searched, setsearched] = useState<string>("");
  const [showesearchclose, setshowesearchclose] = useState<boolean>(false);

  const [type, setType] = useState<string>("all");

  const { allpendingdocument, getAllPendingDocuments } =
    useContext<any>(ManageContext);

  const [allDocs, setAllDocs] = useState<any[]>(allpendingdocument);

  useEffect(() => {
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    getAllPendingDocuments(userId, companyId, type);
  }, [type]);

  useEffect(() => {
    setAllDocs(allpendingdocument);
  }, [allpendingdocument]);

  const [searchParams] = useSearchParams();
  const { getUserInfo } = useContext<any>(SignatureContext);

  const requestSearch = (searchedVal: any) => {
    if (searchedVal?.target?.value === "") {
      setsearched("");
      setshowesearchclose(false);
      setAllDocs(allpendingdocument);
    } else {
      setshowesearchclose(true);
      setsearched(searchedVal.target.value);
      let filteredRows = allDocs.filter(
        (data: any) =>
          JSON.stringify(data)
            .toLowerCase()
            .indexOf(searchedVal.target.value.toString().toLowerCase()) !== -1
      );
      setAllDocs(filteredRows);
    }
  };

  useEffect(() => {
    let companyId = searchParams.get("companyId");
    let userId = searchParams.get("userId");
    if (companyId && userId) {
      sessionStorage.setItem("companyId", companyId);
      sessionStorage.setItem("userId", userId);
    }
    getUserInfo();
  }, []);

  const cancelSearch = () => {
    setsearched("");
    setshowesearchclose(false);
    setAllDocs(allpendingdocument);
  };

  useEffect(() => {
    let allowType = ["pending", "expiresoon", "completed"];

    let docType = searchParams.get("type") || "";
    if (allowType.includes(docType.trim())) {
      setType(docType);
    } else {
      setType("all");
    }
  }, []);

  const getDocumentDataByType = (type: string) => {
    console.log("allpendingdocument firdt one", allpendingdocument);
    console.log("allDocs first ", allDocs);
    if (allDocs && Array.isArray(allDocs)) {
      return allDocs;
    }
    return [];
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <Headers />

        <TemplateSideBar type={type} setType={setType}>
          <div className="flex flex-col w-full px-4">
            <div className="h-4 p-4 m-4 flex items-center justify-between w-full ">
              <div style={{ width: "60%" }}>
                {type === "all" && (
                  <h3 className="text-black">All Templates</h3>
                )}

                {/* {type === "pending" && (
                <h3 className="text-black">Favourite Templates</h3>
              )} */}
              </div>
              <div className="flex w-3/4 items-center justify-end">
                <Search>
                  {<MdSearch className="text-black text-2xl opacity-50" />}
                  <StyledInputBase
                    placeholder="Search Document"
                    inputProps={{ "aria-label": "search" }}
                    value={searched}
                    onChange={(e) => requestSearch(e)}
                    className="text-black"
                  />
                  {showesearchclose && (
                    <MdClose
                      onClick={() => cancelSearch()}
                      className="text-black text-2xl cursor-pointer"
                    />
                  )}
                </Search>
              </div>
            </div>
            <div>
              <Divider />
            </div>
            <AllTemplateDocumentsTable allDocs={getDocumentDataByType(type)} />
          </div>
        </TemplateSideBar>
      </div>
    </>
  );
};
