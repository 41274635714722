import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

interface PricingPlanProps {
  title: string;
  price: string;
  description: string | null;
  details: { featureName: string; description: string | null }[];
  id: string;
}

interface PlansPopupProps {
  open: boolean;
  onClose: () => void;
  plan: PricingPlanProps | null;
  annualPlans: PricingPlanProps[];
  monthlyPlans: PricingPlanProps[];
  activePlan: string;
}

export const PlansPopup: React.FC<PlansPopupProps> = ({
  open,
  onClose,
  plan,
  activePlan,
  annualPlans,
  monthlyPlans,
}) => {
  const [frequency, setFrequency] = useState<string>(activePlan);
  const [selectedPlan, setSelectedPlan] = useState<PricingPlanProps | null>(
    plan
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (plan) {
      const plans = frequency === "annual" ? annualPlans : monthlyPlans;
      const foundPlan = plans.find((p) => p.id === plan.id) || null;
      setSelectedPlan(foundPlan);
    }
  }, [frequency, plan, annualPlans, monthlyPlans]);

  useEffect(() => {
    setFrequency(activePlan);
  }, [activePlan]);

  const handleMyPayNavigation = () => {
    const email = sessionStorage.getItem("userEmail");
    const username = sessionStorage.getItem("userName");
    const userId = sessionStorage.getItem("userId");
    const accessToken = sessionStorage.getItem("accessToken");
    const companyId = sessionStorage.getItem("companyId");

    if (
      email &&
      username &&
      userId &&
      accessToken &&
      companyId &&
      selectedPlan
    ) {
      const planType = frequency === "annual" ? "annual" : "month";

      // Use the selected plan details
      const planId = selectedPlan.id;
      const planName = selectedPlan.title;

      navigate(
        `/myPay?email=${email}&name=${username}&planType=${planType}&planId=${planId}&planName=${planName}`
      );

      // Show a snackbar notification to the user
      enqueueSnackbar("Please complete your payment process", {
        variant: "info",
      });
    } else {
      console.error("Missing sessionStorage values or plan details.");
      enqueueSnackbar("Please complete the payment details or select a plan", {
        variant: "error",
      });
    }
  };

  // Early return if no selected plan is available
  if (!selectedPlan) return null;

  const netAmount =
    frequency === "annual"
      ? parseFloat(selectedPlan.price.replace("$", "")) * 12
      : parseFloat(selectedPlan.price.replace("$", ""));

  const tax = frequency === "annual" ? 0 : 0;
  const total = netAmount + tax;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Order Summary</Typography>
          <IconButton
            onClick={() => {
              onClose();
              setFrequency(activePlan);
              setSelectedPlan(plan);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" width="100%">
          {/* Left 60% - Plan Details */}
          <Box width="60%" paddingRight={2}>
            <Typography variant="h6" gutterBottom>
              Plan Name: {selectedPlan.title} Plan
            </Typography>

            <Typography variant="body1" gutterBottom>
              Plan Description: {selectedPlan.description}
            </Typography>

            <Typography variant="body1">
              Plan Price: {selectedPlan.price}
            </Typography>

            {/* Table for Features with Scroll */}
            <Box
              sx={{
                maxHeight: 330, // Adjust this value as needed
                overflowY: "auto", // Enable scrolling for the table content
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "50%" }}>✒️ Feature</TableCell>
                    <TableCell sx={{ width: "50%" }}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlan.details.map((feature, index) => (
                    <TableRow key={index}>
                      <TableCell>✔️ {feature.featureName}</TableCell>
                      <TableCell>{feature.description || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>

          {/* Right 40% - Frequency Buttons and Amount */}
          <Box
            width="40%"
            paddingLeft={2}
            borderLeft="1px solid #ddd"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Centers items vertically
            alignItems="center" // Centers items horizontally
            height={400}
          >
            {/* Annual Button with Border */}
            <Button
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: 2,
                borderRadius: "8px",
                padding: "12px",
                textAlign: "center",
                border:
                  frequency === "annual"
                    ? "2px solid #1976d2"
                    : "2px solid #ccc",
                backgroundColor:
                  frequency === "annual" ? "#1976d2" : "transparent",
                color: frequency === "annual" ? "#fff" : "#000",
                "&:hover": {
                  backgroundColor:
                    frequency === "annual" ? "#1976d2" : "transparent",
                  borderColor: frequency === "annual" ? "#1976d2" : "#ccc",
                },
              }}
              onClick={() => setFrequency("annual")}
            >
              <span>
                {frequency === "annual" ? <strong>Annual</strong> : "Annual"}
              </span>
            </Button>

            {/* Monthly Button with Border */}
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderRadius: "8px",
                padding: "12px",
                textAlign: "center",
                border:
                  frequency === "monthly"
                    ? "2px solid #1976d2"
                    : "2px solid #ccc",
                backgroundColor:
                  frequency === "monthly" ? "#1976d2" : "transparent",
                color: frequency === "monthly" ? "#fff" : "#000",
                "&:hover": {
                  backgroundColor:
                    frequency === "monthly" ? "#1976d2" : "transparent",
                  borderColor: frequency === "monthly" ? "#1976d2" : "#ccc",
                },
              }}
              onClick={() => setFrequency("monthly")}
            >
              <span>
                {frequency === "monthly" ? <strong>Monthly</strong> : "Monthly"}
              </span>
            </Button>

            {/* Amount Table */}
            <Table sx={{ marginTop: 3 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Details</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Amount</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Net Amount</TableCell>
                  <TableCell align="right">
                    {frequency === "annual"
                      ? `${selectedPlan.price} x 12 = $${netAmount.toFixed(2)}`
                      : `${selectedPlan.price} x 1 = $${netAmount.toFixed(2)}`}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">${tax.toFixed(2)}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>${total.toFixed(2)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Button
              color="primary"
              variant="contained"
              sx={{ width: "100%", marginTop: 2 }}
              onClick={handleMyPayNavigation}
            >
              Proceed to Payment
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setFrequency(activePlan);
            setSelectedPlan(plan);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
