import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSnackbar } from "notistack";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Custom dialog styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Custom title with close button
const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface SaveAsTemplatePopupProps {
  open: boolean;
  onClose: () => void;
  selectedRow: any;
}

export const SaveAsTemplatePopup: React.FC<SaveAsTemplatePopupProps> = ({
  open,
  onClose,
  selectedRow,
}) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState(0);

  // State for template name and description
  const [templateName, setTemplateName] = useState<string>("");
  const [templateDescription, setTemplateDescription] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  // State to store GET API response
  const [apiResponse, setApiResponse] = useState<any>(null);

  // Effect to prefill the template name from selectedRow's Subject
  useEffect(() => {
    if (selectedRow && selectedRow.Subject) {
      setTemplateName(selectedRow?.Subject.trim());
    }
  }, [selectedRow]);

  useEffect(() => {
    const fetchData = async () => {
      const userId = sessionStorage.getItem("userId");
      const companyId = sessionStorage.getItem("companyId");
      const documentHeaderId = selectedRow?.HeaderId;

      // Construct the URL with query parameters
      const params = new URLSearchParams({
        userId: userId || "",
        companyId: companyId || "",
        documentHeaderId: documentHeaderId || "",
      });

      try {
        const response = await fetch(
          `${baseURL}/upload/get-document-detail?${params.toString()}`,
          {
            method: "GET",
            headers: {
              key: headerKey || "",
              Authorization: `Bearer ${
                sessionStorage.getItem("accessToken") || ""
              }`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setApiResponse(data);
        } else {
          const errorData = await response.json();
          enqueueSnackbar(
            `Error: ${errorData.message || "Failed to fetch data"}`,
            { variant: "error" }
          );
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
        enqueueSnackbar("An error occurred while fetching data", {
          variant: "error",
        });
      }
    };

    if (
      sessionStorage.getItem("userId") &&
      sessionStorage.getItem("companyId") &&
      open
    ) {
      fetchData();
    }
  }, [baseURL, open, headerKey, enqueueSnackbar, selectedRow]);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSaveAsTemplateDocument = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/template/save-documents-as-template/${sessionStorage.getItem(
          "userId"
        )}/${sessionStorage.getItem("companyId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: headerKey || "",
            Authorization: `Bearer ${
              sessionStorage.getItem("accessToken") || ""
            }`,
          },
          body: JSON.stringify({
            documentHeaderId: documentHeaderId,
            templateName: templateName,
            templateDescription: templateDescription,
          }),
        }
      );

      if (res.ok) {
        enqueueSnackbar("Document saved successfully as template", {
          variant: "success",
        });
        setTemplateName("");
        setTemplateDescription("");
        onClose();
      } else {
        const errorData = await res.json();
        enqueueSnackbar(
          `Error: ${
            errorData.message || "Failed to save document as template"
          }`,
          { variant: "error" }
        );
      }
    } catch (err) {
      console.error("Failed to save the document as template", err);
      enqueueSnackbar(
        "An error occurred while saving the document as template",
        { variant: "error" }
      );
    }
  };

  const handleSave = async () => {
    if (!templateName || !templateDescription) {
      setSnackbarMessage("Both fields are required!");
      setOpenSnackbar(true);
      return;
    }
    const documentHeaderId = selectedRow?.HeaderId;
    await handleSaveAsTemplateDocument(documentHeaderId);
  };

  useEffect(() => {
    if (apiResponse && apiResponse.length > 0) {
      setSelectedTab(0); // Set the default tab to the first one whenever data is fetched
    }
  }, [apiResponse]);

  const renderPreview = () => {
    if (!apiResponse || apiResponse.length === 0)
      return <div>No document available</div>;

    const filePath = apiResponse[selectedTab]?.convertedDocumentPath;
    const fileExtension = filePath?.split(".").pop()?.toLowerCase();

    if (!filePath) return <div>No file path available</div>;

    if (["pdf"].includes(fileExtension)) {
      return (
        <object
          data={filePath}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              filePath
            )}&embedded=true`}
            style={{ width: "100%", height: "100%" }}
            title="PDF Preview"
          />
        </object>
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return (
        <img
          src={filePath}
          alt="File Preview"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      );
    } else {
      return (
        <a
          href={filePath}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1976d2" }}
        >
          Click here to view the document
        </a>
      );
    }
  };

  const handleTemplateNameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length > 200) {
      enqueueSnackbar("Template name cannot exceed 200 characters.", {
        variant: "error",
      });
      return;
    }
    setTemplateName(e.target.value);
  };

  const handleTemplateDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length > 200) {
      enqueueSnackbar("Template description cannot exceed 200 characters.", {
        variant: "error",
      });
      return;
    }
    setTemplateDescription(e.target.value);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="save-as-template-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      sx={{ height: "620px" }}
    >
      <BootstrapDialogTitle
        id="save-as-template-dialog-title"
        onClose={onClose}
      >
        Save As Template
      </BootstrapDialogTitle>
      <Divider />

      <DialogContent dividers>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box flex={0.3} display="flex" flexDirection="column" mr={2}>
            <TextField
              margin="dense"
              label="Template Name"
              fullWidth
              value={templateName}
              // onChange={(e) => setTemplateName(e.target.value)}
              onChange={handleTemplateNameChange}
              variant="outlined"
              required
              inputProps={{ maxLength: 200 }}
            />
            <TextField
              margin="dense"
              label="Template Description"
              fullWidth
              multiline
              rows={4}
              value={templateDescription}
              // onChange={(e) => setTemplateDescription(e.target.value)}
              onChange={handleTemplateDescriptionChange}
              variant="outlined"
              sx={{ marginTop: 2 }}
              required
              inputProps={{ maxLength: 200 }}
            />
          </Box>
          <Box
            flex={0.7}
            display="flex"
            flexDirection="column" // Ensures tabs are stacked above the preview
            justifyContent="flex-start" // Align content at the start
            alignItems="center"
            border="1px solid lightgray"
            padding={2}
            sx={{
              height: "400px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{ width: "100%", overflowX: "auto", marginBottom: "10px" }}
            >
              {/* Tabs */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                {apiResponse?.map((doc: { id: string }, index: number) => (
                  <button
                    key={doc.id}
                    onClick={() => setSelectedTab(index)}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === index ? "#1976d2" : "#e0e0e0",
                      color: selectedTab === index ? "#fff" : "#000",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      fontWeight: selectedTab === index ? "bold" : "normal",
                    }}
                  >
                    Doc {index + 1}
                  </button>
                ))}
              </div>
            </div>

            {/* Preview */}
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#fff",
                overflow: "auto", // Ensures scrollability for large content
                padding: "10px",
              }}
            >
              {renderPreview()}
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            sx={{ marginRight: 2 }} // Adds spacing between buttons
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained" // Change this to "outlined" if you prefer the outline style
          >
            Save Template
          </Button>
        </div>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </BootstrapDialog>
  );
};
